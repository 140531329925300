import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, exhaustMap, map, of, switchMap } from "rxjs";
import { FlightsService } from "../services/flights.service";
import { FlightsStateActions } from "./flights.actions";

@Injectable()
export class FlightsEffects {
  constructor(
    private actions$: Actions,
    private flightsService: FlightsService
  ) {}
  loadFlightFilterOptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FlightsStateActions.loadFlightFilterOptions),
      exhaustMap((query: any) => {
        //TODO: complete types
        return this.flightsService.getFilterOptions(query.requestQuery).pipe(
          map((res) => {
            const filterOptions = res;
            return FlightsStateActions.loadFlightFilterOptionsSuccess({
              data: {
                filterOptions,
              },
            });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(
              FlightsStateActions.loadFlightFilterOptionsFailure({
                error: error?.message || String(error),
              } as any)
            );
          })
        );
      })
    )
  );

  loadInitialFlights$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FlightsStateActions.loadInitialFlightsData),
      switchMap((query: any) => {
        const { sort } = query.requestQuery.flightsCriteria.searchParams;
        //TODO: complete types
        return this.flightsService.getFlights(query.requestQuery).pipe(
          map((res) => {
            const { flights, offerRequestId } = res;
            const { totalRecordsLeft, totalRecords } = res.metadata;
            return FlightsStateActions.loadInitialFlightsDataSuccess({
              data: { flights, offerRequestId, sort, totalRecordsLeft, totalRecords },
            });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(
              FlightsStateActions.loadInitialFlightsDataFailure({
                error: error?.message || String(error),
              } as any)
            );
          })
        );
      })
    )
  );

  loadMoreFlights$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FlightsStateActions.loadMoreFlights),
      exhaustMap((query: any) => {
        //TODO: complete types
        return this.flightsService.getFlights(query).pipe(
          map((res) => {
            const { flights } = res;
            const { totalRecordsLeft, totalRecords } = res.metadata;

            return FlightsStateActions.loadMoreFlightsSuccess({
              data: { flights, totalRecordsLeft, totalRecords },
            });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(
              FlightsStateActions.loadMoreFlightsFailure({
                error: error?.message || String(error),
              } as any)
            );
          })
        );
      })
    )
  );

}
