import { Action, createReducer, on } from '@ngrx/store';
import { DashboardState } from '../model';
import { dashboardStateActions } from './dashboard.action';
import { DashboardAdapter } from './dashboard.adapter';

export const initialCurrencyState: DashboardState = DashboardAdapter.getInitialState({
  orders: {},
  events: {},
  flights: {},
  hotels: {},
  orderDetail: {},
  eventDetail: {},
  flightDetail: {},
  hotelDetail: {},
  packagesInfo: {},
  personsInfo: {},  
  numberOfOrders: {},
  popularAirlinesInfo:{},
  flightsIncomeInfo:{},
  flightsOutcomeInfo:{},
  flightsProfitInfo:{},
  popularHotelsInfo:{},
  hotelsIncomeInfo:{},
  hotelsOutcomeInfo:{},
  hotelsProfitInfo:{},
  popularEventsInfo:{},
  eventsIncomeInfo:{},
  eventsOutcomeInfo:{},
  eventsProfitInfo:{},
  reservationIncome: {},
  reservationOutcome: {},
  emailObj: {},
  smsObj: {},
  pushObj: {},
  UpdateOrderInfo:{},
  coupons:{},
  CouponInfo:{},
  SaveViewInfo:{},
  OrdersView:{},
  EventsView:{},
  FlightsView:{},
  HotelsView:{},
  ReservationsView:{},
  loading: false,
  loaded: false,
  error: null,
});

export const dashboardStateFeatureKey = 'DashboardStateFeatureKey';

export const dashboardReducer = createReducer(
  initialCurrencyState,
  on(dashboardStateActions.getOrders, (state, action) => {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }),
  on(dashboardStateActions.ordersSuccess, (state, action) => {
    const data = action.data || {}; 
    return {
      ...state,
      orders: data.orders,
      loading: false,
      loaded: true
    };
  }),

  on(dashboardStateActions.getEvents, (state, action) => {
    return {
      ...state
    };
  }),
  on(dashboardStateActions.eventsSuccess, (state, action) => {
    const data = action.data || {}; 
    return {
      ...state,
      events: data.events
    };
  }),
  on(dashboardStateActions.getFlights, (state, action) => {
    return {
      ...state
    };
  }),
  on(dashboardStateActions.flightsSuccess, (state, action) => {
    const data = action.data || {}; 
    return {
      ...state,
      flights: data.flights
    };
  }),
  on(dashboardStateActions.getHotels, (state, action) => {
    return {
      ...state
    };
  }),
  on(dashboardStateActions.hotelsSuccess, (state, action) => {
    const data = action.data || {}; 
    return {
      ...state,
      hotels: data.hotels
    };
  }),
  on(dashboardStateActions.getOrderDetail, (state, action) => {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }),
  on(dashboardStateActions.orderDetailSuccess, (state, action) => {
    const data = action.data || {}; 
    return {
      ...state,
      orderDetail: data.orderDetail,
      loading: false,
      loaded: true
    };
  }),
  on(dashboardStateActions.getEventDetail, (state, action) => {
    return {
      ...state
    };
  }),
  on(dashboardStateActions.eventDetailSuccess, (state, action) => {
    const data = action.data || {}; 
    return {
      ...state,
      eventDetail: data.eventDetail
    };
  }),
  on(dashboardStateActions.getFlightDetail, (state, action) => {
    return {
      ...state
    };
  }),
  on(dashboardStateActions.flightDetailSuccess, (state, action) => {
    const data = action.data || {}; 
    return {
      ...state,
      flightDetail: data.flightDetail
    };
  }),
  on(dashboardStateActions.getHotelDetail, (state, action) => {
    return {
      ...state
    };
  }),
  on(dashboardStateActions.hotelDetailSuccess, (state, action) => {
    const data = action.data || {}; 
    return {
      ...state,
      hotelDetail: data.hotelDetail
    };
  }),
  on(dashboardStateActions.resetOrderDetail, (state, action) => {
    return {
      ...state,
      orderDetail: {}
    };
  }),
  on(dashboardStateActions.resetEventDetail, (state, action) => {
    return {
      ...state,
      eventDetail: {}
    };
  }),
  on(dashboardStateActions.resetFlightDetail, (state, action) => {
    return {
      ...state,
      flightDetail: {}
    };
  }),
  on(dashboardStateActions.resetHotelDetail, (state, action) => {
    return {
      ...state,
      hotelDetail: {}
    };
  }),

  on(dashboardStateActions.loadPackages, (state, action) => {
    return {
      ...state
    };
  }),
  on(dashboardStateActions.loadPackagesSuccess, (state, action) => {
    const data = action.data || {}; 
    return {
      ...state,
      packagesInfo: data
    };
  }),
  on(dashboardStateActions.loadPersons, (state, action) => {
    return {
      ...state
    };
  }),
  on(dashboardStateActions.loadPersonsSuccess, (state, action) => {
    const data = action.data || {}; 
    return {
      ...state,
      personsInfo: data
    };
  }),

// load popular airline flight data
  on(dashboardStateActions.loadPopularAirline, (state, action) => {
    return {
      ...state
    };
  }),
  on(dashboardStateActions.loadPopularAirlineSuccess, (state, action) => {
    const data = action.data || {}; 
    return {
      ...state,
      popularAirlinesInfo: data
    };
  }),
  on(dashboardStateActions.loadPopularAirlineFailure, (state, action) => {
    const { error } = action;
    return {
      ...state,
      error: error
    };
  }),

  // load flights income
    on(dashboardStateActions.loadFlightsIncome, (state, action) => {
      return {
        ...state
      };
    }),
    on(dashboardStateActions.loadFlightsIncomeSuccess, (state, action) => {
      const data = action.data || {}; 
      return {
        ...state,
        flightsIncomeInfo: data
      };
    }),
    on(dashboardStateActions.loadFlightsIncomeFailure, (state, action) => {
      const { error } = action;
      return {
        ...state,
        error: error
      };
    }),

    // load flights outcome
    on(dashboardStateActions.loadFlightsOutcome, (state, action) => {
      return {
        ...state
      };
    }),
    on(dashboardStateActions.loadFlightsOutcomeSuccess, (state, action) => {
      const data = action.data || {}; 
      return {
        ...state,
        flightsOutcomeInfo: data
      };
    }),
    on(dashboardStateActions.loadFlightsOutcomeFailure, (state, action) => {
      const { error } = action;
      return {
        ...state,
        error: error
      };
    }),

    // load flights profit
    on(dashboardStateActions.loadFlightsProfit, (state, action) => {
      return {
        ...state
      };
    }),
    on(dashboardStateActions.loadFlightsProfitSuccess, (state, action) => {
      const data = action.data || {}; 
      return {
        ...state,
        flightsProfitInfo: data
      };
    }),
    on(dashboardStateActions.loadFlightsProfitFailure, (state, action) => {
      const { error } = action;
      return {
        ...state,
        error: error
      };
    }),
    on(dashboardStateActions.weeklyNumberOfOrders, (state, action) => {
      return {
        ...state,
      };
    }),
    on(dashboardStateActions.weeklyNumberOfOrdersSuccess, (state, action) => {
      const data = action.data || {}; 
      return {
        ...state,      
        numberOfOrders: data
      };
    }),
  
   on(dashboardStateActions.weeklyNumberOfOrdersFailure, (state, action) => {
      const { error } = action;
        return {
          ...state,
          error: error
        };
    }),

    // load popular hotels data
  on(dashboardStateActions.loadPopularHotels, (state, action) => {
    return {
      ...state
    };
  }),
  on(dashboardStateActions.loadPopularHotelsSuccess, (state, action) => {
    const data = action.data || {}; 
    return {
      ...state,
      popularHotelsInfo: data
    };
  }),
  on(dashboardStateActions.loadPopularHotelsFailure, (state, action) => {
    const { error } = action;
    return {
      ...state,
      error: error
    };
  }),

  // load hotels income
    on(dashboardStateActions.loadHotelsIncome, (state, action) => {
      return {
        ...state
      };
    }),
    on(dashboardStateActions.loadHotelsIncomeSuccess, (state, action) => {
      const data = action.data || {}; 
      return {
        ...state,
        hotelsIncomeInfo: data
      };
    }),
    on(dashboardStateActions.loadHotelsIncomeFailure, (state, action) => {
      const { error } = action;
      return {
        ...state,
        error: error
      };
    }),

    // load hotels outcome
    on(dashboardStateActions.loadHotelsOutcome, (state, action) => {
      return {
        ...state
      };
    }),
    on(dashboardStateActions.loadHotelsOutcomeSuccess, (state, action) => {
      const data = action.data || {}; 
      return {
        ...state,
        hotelsOutcomeInfo: data
      };
    }),
    on(dashboardStateActions.loadHotelsOutcomeFailure, (state, action) => {
      const { error } = action;
      return {
        ...state,
        error: error
      };
    }),

    // load hotels profit
    on(dashboardStateActions.loadHotelsProfit, (state, action) => {
      return {
        ...state
      };
    }),
    on(dashboardStateActions.loadHotelsProfitSuccess, (state, action) => {
      const data = action.data || {}; 
      return {
        ...state,
        hotelsProfitInfo: data
      };
    }),
    on(dashboardStateActions.loadHotelsProfitFailure, (state, action) => {
      const { error } = action;
      return {
        ...state,
        error: error
      };
    }),

    // load popular events data
  on(dashboardStateActions.loadPopularEvents, (state, action) => {
    return {
      ...state
    };
  }),
  on(dashboardStateActions.loadPopularEventsSuccess, (state, action) => {
    const data = action.data || {}; 
    return {
      ...state,
      popularEventsInfo: data
    };
  }),
  on(dashboardStateActions.loadPopularEventsFailure, (state, action) => {
    const { error } = action;
    return {
      ...state,
      error: error
    };
  }),

  // load events income
    on(dashboardStateActions.loadEventsIncome, (state, action) => {
      return {
        ...state
      };
    }),
    on(dashboardStateActions.loadEventsIncomeSuccess, (state, action) => {
      const data = action.data || {}; 
      return {
        ...state,
        eventsIncomeInfo: data
      };
    }),
    on(dashboardStateActions.loadEventsIncomeFailure, (state, action) => {
      const { error } = action;
      return {
        ...state,
        error: error
      };
    }),

    // load events outcome
    on(dashboardStateActions.loadEventsOutcome, (state, action) => {
      return {
        ...state
      };
    }),
    on(dashboardStateActions.loadEventsOutcomeSuccess, (state, action) => {
      const data = action.data || {}; 
      return {
        ...state,
        eventsOutcomeInfo: data
      };
    }),
    on(dashboardStateActions.loadEventsOutcomeFailure, (state, action) => {
      const { error } = action;
      return {
        ...state,
        error: error
      };
    }),

    // load events profit
    on(dashboardStateActions.loadEventsProfit, (state, action) => {
      return {
        ...state
      };
    }),
    on(dashboardStateActions.loadEventsProfitSuccess, (state, action) => {
      const data = action.data || {}; 
      return {
        ...state,
        eventsProfitInfo: data
      };
    }),
    on(dashboardStateActions.loadEventsProfitFailure, (state, action) => {
      const { error } = action;
      return {
        ...state,
        error: error
      };
    }),
    on(dashboardStateActions.sendSms, (state, action) => {
      return {
        ...state,
      };
    }),
    on(dashboardStateActions.sendSmsSuccess, (state, action) => {
      const data = action.data || {}; 
      return {
        ...state,
        smsObj: data
      };
    }),
    on(dashboardStateActions.sendEmail, (state, action) => {
      return {
        ...state,
      };
    }),
    on(dashboardStateActions.sendEmailSuccess, (state, action) => {
      const data = action.data || {}; 
      return {
        ...state,
        emailObj: data
      };
    }),
    on(dashboardStateActions.sendPushNotification, (state, action) => {
      return {
        ...state,
      };
    }),
    on(dashboardStateActions.sendPushNotificationSuccess, (state, action) => {
      const data = action.data || {}; 
      return {
        ...state,
        pushObj: data
      };
    }),

    // load reservation income
    on(dashboardStateActions.loadReservationIncome, (state, action) => {
      return {
        ...state
      };
    }),
    on(dashboardStateActions.loadReservationIncomeSuccess, (state, action) => {
      const data = action.data || {}; 
      return {
        ...state,
        reservationIncome: data
      };
    }),
    on(dashboardStateActions.loadReservationIncomeFailure, (state, action) => {
      const { error } = action;
      return {
        ...state,
        error: error
      };
    }),

    // load events outcome
    on(dashboardStateActions.loadReservationOutcome, (state, action) => {
      return {
        ...state
      };
    }),
    on(dashboardStateActions.loadReservationOutcomeSuccess, (state, action) => {
      const data = action.data || {}; 
      return {
        ...state,
        reservationOutcome: data
      };
    }),
    on(dashboardStateActions.loadReservationOutcomeFailure, (state, action) => {
      const { error } = action;
      return {
        ...state,
        error: error
      };
    }),
    on(dashboardStateActions.resetSms, (state, action) => {
      return {
        ...state,
        smsObj: {}
      };
    }),
    on(dashboardStateActions.resetEmail, (state, action) => {
      return {
        ...state,
        emailObj: {}
      };
    }),
    on(dashboardStateActions.resetPushNotification, (state, action) => {
      return {
        ...state,
        pushObj: {}
      };
    }),

    on(dashboardStateActions.updateOrderComment, (state, action) => {
      return {
        ...state,
        UpdateOrderInfo: state.UpdateOrderInfo,
        error: null,
      };
    }),
  
    on(dashboardStateActions.updateOrderCommentSuccess, (state, action) => {
      const { UpdateOrderInfo } = action.data || {};
      return {
        ...state,
        UpdateOrderInfo: UpdateOrderInfo,
        error: null,
      };
    }),
  
    on(dashboardStateActions.updateOrderCommentFailure, (state, action) => {
      const { error } = action || {};
      //TODO: add errors
      return {
        ...state,
        UpdateOrderInfo: null,
        error,
      };
    }),

    // Coupon implementation 
    // Add
    on(dashboardStateActions.createCoupon, (state, action) => {
      return {
        ...state,
        CouponInfo: state.CouponInfo,
        loading: true,
        loaded: false,
        error: null,
      };
    }),
  
    on(dashboardStateActions.createCouponSuccess, (state, action) => {
      const { couponInfo } = action.data || {};
      return {
        ...state,
        CouponInfo: couponInfo,
        loading: false,
        loaded: true,
        error: null,
      };
    }),
  
    on(dashboardStateActions.createCouponFailure, (state, action) => {
      const { error } = action || {};
      //TODO: add errors
      return {
        ...state,
        CouponInfo: null,
        loading: false,
        loaded: true,
        error,
      };
    }),

    // Update
    on(dashboardStateActions.updateCoupon, (state, action) => {
      return {
        ...state,
        CouponInfo: state.CouponInfo,
        loading: true,
        loaded: false,
        error: null,
      };
    }),

    on(dashboardStateActions.updateCouponSuccess, (state, action) => {
      const { couponInfo } = action.data || {};
      return {
        ...state,
        CouponInfo: couponInfo,
        loading: false,
        loaded: true,
        error: null,
      };
    }),

    on(dashboardStateActions.updateCouponFailure, (state, action) => {
      const { error } = action || {};
      //TODO: add errors
      return {
        ...state,
        CouponInfo: null,
        loading: false,
        loaded: true,
        error,
      };
    }),
    
    //Get All
    on(dashboardStateActions.getCoupons, (state, action) => {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }),
    on(dashboardStateActions.getCouponsSuccess, (state, action) => {
      const data = action.data || {}; 
      return {
        ...state,
        coupons: data.coupons,
        loading: false,
        loaded: true
      };
    }),

    on(dashboardStateActions.getCouponsFailure, (state, action) => {
      const { error } = action || {};
      return {
        ...state,
        coupons: null,
        loading: false,
        loaded: true,
        error,
      };
    }),

    // Delete

    on(dashboardStateActions.deleteCoupon, (state, action) => {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }),
  
    on(dashboardStateActions.deleteCouponSuccess, (state, action) => {
      const data = action.data || {}; 
      return {
        ...state,
        CouponInfo: data,
        loading: false,
        loaded: true
      };
    }),

    on(dashboardStateActions.deleteCouponFailure, (state, action) => {
      const { error } = action || {};
      return {
        ...state,
        coupons: null,
        loading: false,
        loaded: true,
        error,
      };
    }),

      // Save View implementation 

    on(dashboardStateActions.saveView, (state, action) => {
      return {
        ...state,
        SaveViewInfo: state.SaveViewInfo,
        loading: true,
        loaded: false,
        error: null,
      };
    }),
  
    on(dashboardStateActions.saveViewSuccess, (state, action) => {
      const { SaveViewInfo } = action.data || {};
      return {
        ...state,
        SaveViewInfo: SaveViewInfo,
        loading: false,
        loaded: true,
        error: null,
      };
    }),
  
    on(dashboardStateActions.saveViewFailure, (state, action) => {
      const { error } = action || {};
      //TODO: add errors
      return {
        ...state,
        SaveViewInfo: null,
        loading: false,
        loaded: true,
        error,
      };
    }),

    on(dashboardStateActions.getOrdersViewDetail, (state, action) => {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }),
    
    on(dashboardStateActions.getOrdersViewSuccess, (state, action) => {
      const data = action.data || {}; 
      return {
        ...state,
        OrdersView: data.OrdersView,
        loading: false,
        loaded: true
      };
    }),

    on(dashboardStateActions.getOrdersViewFailure, (state, action) => {
      const { error } = action || {};
      return {
        ...state,
        ordersView: null,
        loading: false,
        loaded: true,
        error,
      };
    }),

    on(dashboardStateActions.getEventsViewDetail, (state, action) => {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }),

    on(dashboardStateActions.getEventsViewSuccess, (state, action) => {
      const data = action.data || {};
      return {
        ...state,
        EventsView: data.eventsView,
        loading: false,
        loaded: true
      };
    }),

    on(dashboardStateActions.getEventsViewFailure, (state, action) => {
      const { error } = action || {};
      return {
        ...state,
        EventsView: null,
        loading: false,
        loaded: true,
        error,
      };
    }),

    on(dashboardStateActions.getFlightsViewDetail, (state, action) => {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }),

    on(dashboardStateActions.getFlightsViewSuccess, (state, action) => {
      const data = action.data || {}; 
      return {
        ...state,
        FlightsView: data.flightsView,
        loading: false,
        loaded: true
      };
    }),

    on(dashboardStateActions.getFlightsViewFailure, (state, action) => {
      const { error } = action || {};
      return {
        ...state,
        FlightsView: null,
        loading: false,
        loaded: true,
        error,
      };
    }),

    on(dashboardStateActions.getHotelsViewDetail, (state, action) => {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }),

    on(dashboardStateActions.getHotelsViewSuccess, (state, action) => {
      const data = action.data || {}; 
      return {
        ...state,
        HotelsView: data.hotelsView,
        loading: false,
        loaded: true
      };
    }),

    on(dashboardStateActions.getHotelsViewFailure, (state, action) => {
      const { error } = action || {};
      return {
        ...state,
        HotelsView: null,
        loading: false,
        loaded: true,
        error,
      };
    }),

    on(dashboardStateActions.getReservationsViewDetail, (state, action) => {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }),

    on(dashboardStateActions.getReservationsViewSuccess, (state, action) => {
      const data = action.data || {}; 
      return {
        ...state,
        ReservationsView: data.reservationsView,
        loading: false,
        loaded: true
      };
    }),

    on(dashboardStateActions.getReservationsViewFailure, (state, action) => {
      const { error } = action || {};
      return {
        ...state,
        ReservationsView: null,
        loading: false,
        loaded: true,
        error,
      };
    }),


  );

export function DashboardReducer(state: DashboardState | undefined, action: Action) {
  return dashboardReducer(state, action);
}