import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  requestOptions: any;
  loading: boolean = false;
  page: number = 1;
  pageSize: number = 10;
  collectionSize: number = 0;
  constructor(
    private toaster: ToastrService
  ) { }

  Success(msg:string){
    this.toaster.success(msg, 'Info', {
   timeOut: 3000,
 });
   }
   Error(msg:string){
    this.toaster.error(msg, 'Error', {
   timeOut: 3000,
 });
   }
}
