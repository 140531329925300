import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { DashboardFacade } from 'src/app/pages/admin-dashboard/data-access/services/dashboard.facade';

@Component({
  selector: 'tic-email-notification',
  templateUrl: './email-notification.component.html',
  styleUrls: ['./email-notification.component.scss']
})
export class EmailNotificationComponent {
  @Output() close = new EventEmitter<boolean>();
  @Output() sendEmailNotification = new EventEmitter<any>();
  @Input() emailList: any;
  myForm: FormGroup;
  emailSuccess = false;
  emailMessage: any;
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
        { class: 'arial', name: 'Arial' },
        { class: 'times-new-roman', name: 'Times New Roman' },
        { class: 'calibri', name: 'Calibri' },
        { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
        {
            name: 'quote',
            class: 'quote'
        },
        {
            name: 'redText',
            class: 'redText'
        },
        {
            name: 'titleText',
            class: 'titleText',
            tag: 'h1'
        }
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize', 'insertImage', 'insertVideo', 'textColor', 'backgroundColor', 'customClasses']
    ],
    
  };
  constructor(private formBuilder: FormBuilder, public dashboardFacade: DashboardFacade) { }

  ngOnInit(): void {
    this.myForm = this.formBuilder.group({
      //emails: ['', [Validators.required, this.emailListValidator()]],
      subject: ['', Validators.required],
      html: ['', Validators.required]
    });
    this.emailValidation();
  }
  
  emailValidation() {
    this.dashboardFacade.emailObj$.subscribe(emailObj => {
      this.emailMessage = emailObj
      if (this.emailSuccess) {
        this.emailSuccess = true;
        setTimeout(() => {
          this.emailSuccess = false;
        }, 2000); // Hide the message after 2 seconds
      }
    });
  }

  emailListValidator() {
    return (control: FormControl): ValidationErrors | null => {
      const emailList = control.value;
      
      if (!emailList) {
        return null; // No validation error if the field is empty
      }
  
      // Check if the input contains commas
      const hasCommas = emailList.includes(',');
      if (hasCommas) {
        // Split the input into individual email addresses
        const emailsArray = emailList.split(',').map(email => email.trim());
  
        // Validate each email in the array
        const invalidEmails = emailsArray.filter(email => !this.isValidEmail(email));
  
        if (invalidEmails.length > 0) {
          return { invalidEmails: true }; // Validation error if any email is invalid
        }
      } else {
        // No commas found, treat the input as a single email
        const isValidSingleEmail = this.isValidEmail(emailList);
  
        if (!isValidSingleEmail) {
          return { invalidEmails: true }; // Validation error for a single invalid email
        }
      }
  
      return null; // No validation error if all emails are valid or it's a valid single email
    };
  }


  isValidEmail(email: string): boolean {
    // Basic email validation using regex
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  }

  closePopup() {
    this.close.emit(true);
  }

  submitForm(event: Event) {
    event.preventDefault();
    if (this.myForm.valid) {
      // const commaSeparatedEmails = this.myForm.value.emails;
      // if (commaSeparatedEmails.includes(',')) {
      //   const emailsArray = commaSeparatedEmails.split(',').map(email => email.trim());
      //   this.myForm.patchValue({ emails: emailsArray });
      // } else {
      //   this.myForm.patchValue({ emails: [commaSeparatedEmails] });
      // }
      this.dashboardFacade.resetEmail();
      this.sendEmailNotification.emit(this.myForm.value);
      this.emailSuccess = true;
    } else {
      console.error('Form is invalid.');
    }
  }
}