<h3 class="font-weight-bold">{{'dashboard.allTripPage.welcomeTitle' | i18n}}</h3>
<div class="btn-list">
  <tic-button class="blue" buttonText="dashboard.buttons.csv" [disabled]="!orderList || orderList.length === 0" (btnClick)="exportToCsv()" [matTooltip]="!orderList || orderList.length === 0 ? 'No orders available.' : ''"></tic-button>
  <tic-button class="yellow" buttonText="dashboard.buttons.pdf" [disabled]="!orderList || orderList.length === 0" (btnClick)="exportToPdf()" [matTooltip]="!orderList || orderList.length === 0 ? 'No orders available.' : ''"></tic-button>
  <tic-button class="pink" buttonText="dashboard.buttons.sms" [ngClass]="{'disabled': !hasCheckedStates()}" [matTooltip]="!hasCheckedStates() ? 'Select order to proceed.' : ''" (btnClick)="sendSms()"></tic-button>
  <tic-button class="black" buttonText="dashboard.buttons.email" [ngClass]="{'disabled': !hasCheckedStates()}" [matTooltip]="!hasCheckedStates() ? 'Select order to proceed.' : ''" (btnClick)="sendEmail()"></tic-button>
  <tic-button class="skyblue" buttonText="dashboard.buttons.push" (btnClick)="sentPushNotification()"></tic-button>
</div>
<div class="card-body" #tableRef>
  <div class="table-responsive flipped" *ngIf="dashboardFacade.vm$ | async as vm">
    <div class="flipped_inner" *ngIf="vm.loaded; else loader">
      <div *ngIf="vm.orders.length > 0">
        <table mat-table class="mat-elevation-z8" [dataSource]="this.orderList">
          <!-- Checkbox Column -->
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox (change)="masterToggle($event)">
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row; let i = index">
              <mat-checkbox
              (change)="singleToggle($event,i)"
              [checked]="checkboxStates[i].state"
              >
              </mat-checkbox>
            </td>
          </ng-container>

          <!-- Order Id Column -->
          <ng-container matColumnDef="orderId">
            <th mat-header-cell *matHeaderCellDef>
              <span class="inner_data">
                Order Id
                <span class="arrows">
                  <span class="arrow_up">
                    <img src="assets/images/up.svg">
                  </span>
                  <span class="arrow_down">
                    <img src="assets/images/down.svg">                    
                  </span>
                </span>
              </span>
            </th>
            <td mat-cell *matCellDef="let order" (click)="orderDetail(order.orderId)"> {{ order.orderId }} </td>
          </ng-container>
        
          <!-- Events Column -->
          <ng-container matColumnDef="events">
            <th mat-header-cell *matHeaderCellDef>
              <span class="inner_data">
                Events
                <span class="arrows">
                  <span class="arrow_up">
                    <img src="assets/images/up.svg">
                  </span>
                  <span class="arrow_down">
                    <img src="assets/images/down.svg">                    
                  </span>
                </span>
              </span>
            </th>
            <td mat-cell *matCellDef="let order">
              <span *ngFor="let event of order.ticketsOrders" (click)="eventDetail(order)">
                {{ event.name }}
              </span>
            </td>
          </ng-container>
        
          <!-- Flight Column -->
          <ng-container matColumnDef="flight">
            <th mat-header-cell *matHeaderCellDef>
              <span class="inner_data">
                Flights
                <span class="arrows">
                  <span class="arrow_up">
                    <img src="assets/images/up.svg">
                  </span>
                  <span class="arrow_down">
                    <img src="assets/images/down.svg">                    
                  </span>
                </span>
              </span>
            </th>
            <td mat-cell *matCellDef="let order">
              <span *ngIf="order.flightOrder.name; else noClick" (click)="flightDetail(order)">
                {{ order.flightOrder.name }}
              </span>
              <ng-template #noClick>
                {{ order.flightOrder.name }}
              </ng-template>
            </td>
          </ng-container>          
        
          <!-- Hotels Column -->
          <ng-container matColumnDef="hotels">
            <th mat-header-cell *matHeaderCellDef>
              <span class="inner_data">
                Hotels
                <span class="arrows">
                  <span class="arrow_up">
                    <img src="assets/images/up.svg">
                  </span>
                  <span class="arrow_down">
                    <img src="assets/images/down.svg">                    
                  </span>
                </span>
              </span>
            </th>
            <td mat-cell *matCellDef="let order">
              <span *ngIf="order.hotelOrder.name; else noClick" (click)="hotelDetail(order)">
                {{ order.hotelOrder.name }}
              </span>
              <ng-template #noClick>
                {{ order.hotelOrder.name }}
              </ng-template>
            </td>
          </ng-container>          
        
          <!-- Status Column -->
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>
              <span class="inner_data">
                Status
                <span class="arrows">
                  <span class="arrow_up">
                    <img src="assets/images/up.svg">
                  </span>
                  <span class="arrow_down">
                    <img src="assets/images/down.svg">                    
                  </span>
                </span>
              </span>
            </th>
            <td mat-cell *matCellDef="let order" (click)="orderDetail(order.orderId)">
              <span class="badge {{ order.orderStatus }}">{{ order.orderStatus }}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="comments">
            <th mat-header-cell *matHeaderCellDef>
              Comments
            </th>
            <td mat-cell *matCellDef="let order" (click)="openComments(order)">
              <span class="badge">{{ order.comment }}</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="createdAt">
            <th mat-header-cell *matHeaderCellDef> Created At </th>
            <td mat-cell *matCellDef="let order">
              <span>{{ order.createdAt | date: 'dd-MMM-yyyy' }}</span>
            </td>
          </ng-container>
        
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;; let i = index" [ngClass]="{'selected-row': checkboxStates[i]?.state}"></tr>
        </table>      
      </div>
    </div>
    <ng-template #loader>
      <tic-loader></tic-loader>
  </ng-template>
  </div>
</div>
  <ng-container *ngIf="showCommentsPopup">
    <tic-comments-popup (close)="closePopup()" 
    (sendComment)="sendOrderComment($event)" 
    [passengerName]="currentOrder.contactDetail.firstName +' '+ currentOrder.contactDetail.lastName"
    [preComment]="currentOrder.comment">
    </tic-comments-popup>
  </ng-container>
  <ng-container *ngIf="showEmailPopup">
    <tic-email-notification [emailList]="emailList" (close)="closeEmailPopup()" (sendEmailNotification)="sendEmailNotification($event)"></tic-email-notification>
  </ng-container>
  <ng-container *ngIf="showSmsPopup">
    <tic-sms-notification [phoneList]="phoneList" (close)="closeSmsPopup()" (sendSmsNotification)="sendSmsNotification($event)"></tic-sms-notification>
  </ng-container>
  <ng-container *ngIf="showPushPopup">
    <tic-push-notification (close)="closePushPopup()"></tic-push-notification>
  </ng-container>
  <ng-container *ngIf="showEventDetail">
    <tic-event-table [order]="currentOrder" (close)="closeEventDetail()"></tic-event-table>
  </ng-container>
  <ng-container *ngIf="showFlightDetail">
    <tic-flight-table [order]="currentOrder" (close)="closeFlightDetail()"></tic-flight-table>
  </ng-container>
  <ng-container *ngIf="showHotelDetail">
    <tic-hotel-table [order]="currentOrder" (close)="closeHotelDetail()"></tic-hotel-table>
  </ng-container>