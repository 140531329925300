import { ChangeDetectorRef, Component, EventEmitter, Output } from '@angular/core';
import { DashboardFacade } from '../../../data-access/services/dashboard.facade';
import * as Highcharts from 'highcharts';
import { Subject, takeUntil } from 'rxjs';
import { ViewFilter } from '../../../data-access/model/filters';

@Component({
  selector: 'tic-reservation-detail',
  templateUrl: './reservation-detail.component.html',
  styleUrls: ['./reservation-detail.component.scss']
})
export class ReservationDetailComponent {
  requestQueryReservationIncome: any;
  requestQueryReservationOutcome: any;
  incomeFilter: any;
  outcomeFilter: any;
  incomeChart: string = 'column';
  outcomeChart: string = 'column';
  statusTitle: string = 'Status';
  status = [
    { value: 'none', viewValue: 'None' },
    { value: 'PENDING', viewValue: 'PENDING' },
    { value: 'READY', viewValue: 'READY' },
    { value: 'IN_PROGRESS', viewValue: 'IN PROGRESS' },
    { value: 'COMPLETED ', viewValue: 'COMPLETED ' },
    { value: 'CANCELLED', viewValue: 'CANCELLED' },
  ];
  priceTitle: string = 'Price';
  price = [
    { value: 'originPrice', viewValue: 'Origin Price' },
    { value: 'ourPrice', viewValue: 'Our Price' },
    { value: 'profit', viewValue: 'Profit' },
  ];
  colors = ['#4B49AC', '#98BDFF'];
  colors1 = ['#4B49AC', '#FFC100'];
  colors2 = ['#248AFD'];
  colors3 = ['#FF73A6'];  
  Highcharts: typeof Highcharts = Highcharts;
  private destroy$ = new Subject();
  public chartOptions: Highcharts.Options;
  public chartOptions1: Highcharts.Options;
  showSaveViewPopup: boolean=false;
  reservationsView: any;
  Info: any;
  showValidation: boolean;
  constructor(private cdr: ChangeDetectorRef,public dashboardFacade: DashboardFacade) {
  }

  ngOnInit() {
    this.setInitialState();
  }

  setInitialState() {
    this.dashboardFacade.loadReservationIncome({});
    this.dashboardFacade.loadReservationOutcome({});

    this.toggleChart(this.incomeChart, "income");
    this.toggleChart(this.outcomeChart, "outcome");

    let user = localStorage.getItem('userId');
    this.dashboardFacade.getReservationsView({username: user});
    this.dashboardFacade.ReservationsView$.pipe(takeUntil(this.destroy$)).subscribe((view) => {
      this.reservationsView = view;
    });  
  }

  filterByRange(event: any,type: string) {
    if( type === 'income' ) {
      if( event != 'none') {
        const obj = {
          fromDate: event.startDate,
          toDate: event.endDate 
        }
        this.requestQueryReservationIncome = {
          ...this.requestQueryReservationIncome,
          ...obj
        }
      } else {
        const { fromDate, toDate, ...rest } = this.requestQueryReservationIncome;
        this.requestQueryReservationIncome = rest;
      }
      this.dashboardFacade.loadReservationIncome(this.requestQueryReservationIncome);
    } 
    else if( type === 'outcome' ) {
      if( event != 'none') {
        const obj = {
          fromDate: event.startDate,
          toDate: event.endDate 
        }
        this.requestQueryReservationOutcome = {
          ...this.requestQueryReservationOutcome,
          ...obj
        }
      } else {
        const { fromDate, toDate, ...rest } = this.requestQueryReservationOutcome;
        this.requestQueryReservationOutcome = rest;
      }
      this.dashboardFacade.loadReservationOutcome(this.requestQueryReservationOutcome);
    }
  }

  dataFilter(event: any,type: string,dropdownType: string) {
    if( type === 'income' ) {
        if( dropdownType === 'status' ) {
          if( event != 'none' ) {
            this.incomeFilter = {
              ...this.incomeFilter,
              orderStatus: event
            }
          } else {
            const { status, ...rest } = this.incomeFilter;
            this.incomeFilter = rest;
          }
        } else if( dropdownType === 'price' ) {
          if( event.option != 'none' ) {
            if( event.option ) {
              this.incomeFilter = {
                ...this.incomeFilter,
                priceType: event.option,
                minPrice: event.minPrice,
                maxPrice: event.maxPrice
              }
            } else {
              this.incomeFilter = {
                ...this.incomeFilter,
                minPrice: event.minPrice,
                maxPrice: event.maxPrice
              }
            }
          } else {
            const { priceType, minPrice, maxPrice, ...rest } = this.incomeFilter;
            this.incomeFilter = rest;
          }
        }
        this.requestQueryReservationIncome = {
          ...this.requestQueryReservationIncome,
          filters: this.incomeFilter
        }
        this.dashboardFacade.loadReservationIncome(this.requestQueryReservationIncome);
    } else if( type === 'outcome' ) {
      if( dropdownType === 'status' ) {
        if( event != 'none' ) {
          this.outcomeFilter = {
            ...this.outcomeFilter,
            orderStatus: event
          }
        } else {
          const { status, ...rest } = this.outcomeFilter;
          this.outcomeFilter = rest;
        }
      } else if( dropdownType === 'price' ) {
        if( event.option != 'none' ) {
          if( event.option ) {
            this.outcomeFilter = {
              ...this.outcomeFilter,
              priceType: event.option,
              minPrice: event.minPrice,
              maxPrice: event.maxPrice
            }
          } else {
            this.outcomeFilter = {
              ...this.outcomeFilter,
              minPrice: event.minPrice,
              maxPrice: event.maxPrice
            }
          }
        } else {
          const { priceType, minPrice, maxPrice, ...rest } = this.outcomeFilter;
          this.outcomeFilter = rest;
        }
      }
      this.requestQueryReservationOutcome = {
        ...this.requestQueryReservationOutcome,
        filters: this.outcomeFilter
      }
      this.dashboardFacade.loadReservationOutcome(this.requestQueryReservationOutcome);
    }
  } 

  toggleChart(event: any, type: string) {
    if (type === "income") {
      this.dashboardFacade.reservationIncome$
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          if (data) {
            this.incomeChart = event;
            const categories = Object.keys(data);
            const seriesData = categories.map((category) => {
              const income = data[category].income;
              return {
                name: category || 'Unknown', // Use a meaningful name for each category
                y: income,
                orderIds: income, // Store all order IDs for tooltip
              };
            });

            this.chartOptions = {
              series: [
                {
                  name: 'Income',
                  type: event, // Use 'column' type for a column chart
                  data: seriesData,
                  colorByPoint: true,
                  colors: this.colors1,
                  showInLegend: false,
                },
              ],
              colors: this.colors1,
              title: null,
              xAxis: {
                visible: true,
                type: "category",
                categories: categories.map((cat) => cat || 'Unknown'), // Display as 'Package 2', 'Package 3', etc.
                title: {
                  text: "Date",
                },
              },
              yAxis: {
                visible: true,
                title: {
                  text: "Income",
                },labels: {
                  formatter: function () {
                      return '$' + this.value; // Format yAxis labels with $ sign
                  }
              }
              },
              legend: {
                layout: "vertical",
                align: "right",
                verticalAlign: "middle",
                borderWidth: 0,
              },
              credits: {
                enabled: false,
              },
            };
          }
        });
    } else if (type === "outcome") {
      this.dashboardFacade.reservationOutcome$
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          if (data) {
            this.outcomeChart = event;
            const categories = Object.keys(data);
            const seriesData = categories.map((category) => {
              const outcome = data[category].outcome;
              return {
                name: category || 'Unknown', // Use a meaningful name for each category
                y: outcome,
                orderIds: outcome, // Store all order IDs for tooltip
              };
            });

            this.chartOptions1 = {
              series: [
                {
                  name: 'Outcome',
                  type: event, // Use 'column' type for a column chart
                  data: seriesData,
                  colors: this.colors2,
                  showInLegend: false,
                },
              ],
              colors: this.colors2,
              title: null,
              xAxis: {
                visible: true,
                type: "category",
                categories: categories.map((cat) => cat || 'Unknown'), // Display as 'Package 2', 'Package 3', etc.
                title: {
                  text: "Date",
                },
              },
              yAxis: {
                visible: true,
                title: {
                  text: "Outcome",
                },labels: {
                  formatter: function () {
                      return '$' + this.value; // Format yAxis labels with $ sign
                  }
              }
              },
              legend: {
                layout: "vertical",
                align: "right",
                verticalAlign: "middle",
                borderWidth: 0,
              },
              credits: {
                enabled: false,
              },
            };
          }
        });
    }
  }

  saveView(event:any) {
    let name =event.viewName;   
    let username=localStorage.getItem('userId');

    const reservationFilters = {
      requestQueryReservationIncome: this.requestQueryReservationIncome,
      incomeChart: this.incomeChart,
      requestQueryReservationOutcome: this.requestQueryReservationOutcome,
      outcomeChart: this.outcomeChart
    }

   let data:ViewFilter={
    username:username,
    view:'reservation',
    singleView: {
      name: name,
      param: this.objectToBase64(reservationFilters)
     }
   }
    this.dashboardFacade.saveView(data);
    this.dashboardFacade.SaveViewInfo$.pipe(takeUntil(this.destroy$)).subscribe((info) => {
      this.Info = info;
    });
    this.showValidation = true;
    setTimeout(() => {
      this.showValidation = false;
      this.dashboardFacade.getReservationsView({username: username});
    }, 3000);
    this.showSaveViewPopup = false;     
  }

  objectToBase64(obj: any): string {
    const jsonStr = JSON.stringify(obj);
    return btoa(jsonStr);
  }

  base64ToObject(base64Str: string): any {
    const jsonStr = atob(base64Str);
    return JSON.parse(jsonStr);
  }

  onSelectionChange(event: any) {
    var reservationFilter;
    if( event.value ) {
      reservationFilter = this.base64ToObject(event.value);
    } else {
      reservationFilter = {};
    }
    if( reservationFilter && reservationFilter.requestQueryReservationIncome ) {
      this.requestQueryReservationIncome = reservationFilter.requestQueryReservationIncome;
      this.dashboardFacade.loadReservationIncome(this.requestQueryReservationIncome);
      if( reservationFilter.requestQueryReservationIncome.filters ) {
        this.incomeFilter = reservationFilter.requestQueryReservationIncome.filters;
      }
    } else {
      this.dashboardFacade.loadReservationIncome({});
    }
    if( reservationFilter && reservationFilter.requestQueryReservationOutcome ) {
      this.requestQueryReservationOutcome = reservationFilter.requestQueryReservationOutcome;
      this.dashboardFacade.loadReservationOutcome(this.requestQueryReservationOutcome);
      if( reservationFilter.requestQueryReservationOutcome.filters ) {
        this.outcomeFilter = reservationFilter.requestQueryReservationOutcome.filters;
      }
    } else {
      this.dashboardFacade.loadReservationOutcome({});
    }
  
    if( reservationFilter && reservationFilter.incomeChart ) {
      this.incomeChart = reservationFilter.incomeChart;
    } else {
      this.incomeChart = 'column';
    }
    if( reservationFilter && reservationFilter.outcomeChart ) {
      this.outcomeChart = reservationFilter.outcomeChart;
    } else {
      this.outcomeChart = 'column';
    }
    this.toggleChart(this.incomeChart, "income");
    this.toggleChart(this.outcomeChart, "outcome");
  }

  isObjectNotEmpty(obj: any): boolean {
    return obj && Object.keys(obj).length > 0;
  }

  openSaveView(){
    this.showSaveViewPopup = true;
  }

  closeViewPopup() {
    this.showSaveViewPopup = false;
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
