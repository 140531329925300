import { Component } from '@angular/core';

@Component({
  selector: 'tic-offline-dialog',
  templateUrl: './offline-dialog.component.html',
  styleUrls: ['./offline-dialog.component.scss']
})
export class OfflineDialogComponent {

}
