import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { OrderComment, orderData } from '../../admin-dashboard.config';
import { DashboardFacade } from '../../../data-access/services/dashboard.facade';
import { saveAs } from 'file-saver';
import { jsPDF } from 'jspdf';
import { Subject, takeUntil } from 'rxjs';
import autoTable from 'jspdf-autotable';
import { Router } from '@angular/router';
@Component({
  selector: 'tic-orders-table',
  templateUrl: './orders-table.component.html',
  styleUrls: ['./orders-table.component.scss']
})
export class OrdersTableComponent {
  @Output() selectedOrder = new EventEmitter<any>();
  @ViewChild('tableRef') tableRef: ElementRef;
  @Input() searchString: string='';
  private destroy$ = new Subject<void>();
  orderInfo: orderData[] = orderData;
  displayedColumns: string[] = ['select','orderId', 'events', 'flight', 'hotels', 'status', 'comments', 'createdAt'];
  showCommentsPopup: boolean = false;
  showEmailPopup: boolean = false;
  showSmsPopup: boolean = false;
  showPushPopup: boolean = false;
  showEventDetail: boolean = false;
  showFlightDetail: boolean = false;
  showHotelDetail: boolean = false;
  orderList = [];
  orderListTemp=[];
  checkboxStates = [];
  currentOrder: any;
  emailList = [];
  phoneList = [];

  constructor(public dashboardFacade: DashboardFacade, private router: Router) { 
    this.setInitialState();
    this.dashboardFacade.getOrders();
   }

   ngOnChanges(): void {
    this.orderListTemp=this.orderList;
    if (this.searchString) {
      const searchStringLower = this.searchString.trim().toLowerCase();
    
      this.orderList = this.orderList.filter(item => {
        return this.objectContainsSearchString(item, searchStringLower);
      });
    } else {
      this.dashboardFacade.orders$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
        if (data && data.length > 0) {
          this.orderList = data;
        }
      });
    }
  }

  private objectContainsSearchString(obj: any, searchString: string): boolean {
    // Iterate over each property of the object
    return Object.values(obj).some(value => {
      if (typeof value === 'object' && value !== null) {
        // Recursively check nested objects
        return this.objectContainsSearchString(value, searchString);
      } else {
        // Convert non-string values to strings and perform a case-insensitive check
        const stringValue = (value === null || value === undefined) ? '' : value.toString().toLowerCase();
        return stringValue.includes(searchString);
      }
    });
  }

   setInitialState() {
    this.dashboardFacade.orders$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      if( data && data.length > 0 ) {
        this.orderList = data;
        this.orderList.forEach((item,index) => {
          this.checkboxStates[index] = {
            state: false,
            data: item
          };
        });
      }
    });
   }

  orderDetail(id: string) {
    this.dashboardFacade.resetOrderDetail();
    this.router.navigate(['/dashboard'],
         {queryParams: {tab: 'singleOrder',orderId: id}});
  }

  openComments(order:any) {
    if(order){
      this.currentOrder=order;
    }
    this.showCommentsPopup = true;
  }

  closePopup() {
    this.showCommentsPopup = false;
  }

  exportToCsv() {
    setTimeout(() => {
        if (this.tableRef && this.tableRef.nativeElement) {
            const table = this.tableRef.nativeElement;
            const rows = table.querySelectorAll('tr');
            const csv = [];

            // Convert text to Title Case
            const toTitleCase = (text: string) => {
                return text.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
            };

            // Escape CSV values
            const escapeCsvValue = (text: string) => {
                if (!text) return '""'; // Handle empty values
                text = text.replace(/"/g, '""'); // Escape double quotes
                return `"${text}"`; // Wrap in double quotes
            };

            // Add the title row
            const reportTitle = 'Trippi Orders Report'; // Modify as needed
            csv.push(reportTitle);
            csv.push(''); // Empty row for spacing

            // Add the header row
            const headerRow = table.querySelector('tr');
            if (headerRow) {
                const headerArray = [];
                headerRow.querySelectorAll('th:not(:first-child)').forEach((headerCell) => {
                    headerArray.push(escapeCsvValue(toTitleCase(headerCell.textContent.trim()))); // Convert to Title Case & escape
                });
                csv.push(headerArray.join(',')); // Add headers to CSV
            }

            // Check if any row is checked
            let isAnyRowChecked = false;

            // Add the data rows
            rows.forEach((row, index) => {
                if (index > 0) { // Skip the header row
                    const rowArray = [];
                    const checkbox = row.querySelector('td mat-checkbox input');
                    if (checkbox && checkbox.checked) {
                        isAnyRowChecked = true;
                        row.querySelectorAll('td:not(:first-child)').forEach((cell) => {
                            rowArray.push(escapeCsvValue(toTitleCase(cell.textContent.trim()))); // Convert to Title Case & escape
                        });
                        csv.push(rowArray.join(',')); // Add selected rows
                    }
                }
            });

            // If no row is checked, export all rows
            if (!isAnyRowChecked) {
                rows.forEach((row, index) => {
                    if (index > 0) { // Skip the header row
                        const rowArray = [];
                        row.querySelectorAll('td:not(:first-child)').forEach((cell) => {
                            rowArray.push(escapeCsvValue(toTitleCase(cell.textContent.trim()))); // Convert to Title Case & escape
                        });
                        csv.push(rowArray.join(','));
                    }
                });
            }

            if (csv.length > 2) { // Ensure there is data beyond the title and header
                const csvContent = csv.join('\n');
                const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
                saveAs(blob, 'Trippi_Orders_Report.csv');
            } else {
                console.error('No data available for export.');
            }
        } else {
            console.error('Table reference not initialized or element not found.');
        }
    }, 0);
}

exportToPdf() {
  if (this.tableRef && this.tableRef.nativeElement) {
      const originalTable = this.tableRef.nativeElement;
      const pdf = new jsPDF();

      // Convert text to Title Case
      const toTitleCase = (text: string) => {
          return text.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
      };

      // Add Report Title
      const reportTitle = 'Trippi Orders Report';
      pdf.setFontSize(14);
      pdf.setTextColor(0, 0, 0); // Keep original color scheme (black text)
      pdf.setFont('helvetica', 'bold');
      pdf.text(reportTitle, pdf.internal.pageSize.getWidth() / 2, 15, { align: 'center' });

      // Extract headers
      const headers = [];
      const headerCells = originalTable.querySelectorAll('tr:first-child th');
      headerCells.forEach(cell => {
          headers.push(toTitleCase(cell.textContent.trim())); // Convert to Title Case
      });

      // Add Hierarchy Column
      headers.unshift('Hierarchy');

      // Extract Table Data
      const data = [];
      let isAnyRowChecked = false;
      const rows = originalTable.querySelectorAll('tr');
      let hierarchyCounter = 1;

      for (let i = 1; i < rows.length; i++) {
          const rowData = [];
          const cells = rows[i].querySelectorAll('td');
          const checkbox = rows[i].querySelector('td mat-checkbox input') as HTMLInputElement;
          if (checkbox && checkbox.checked) {
              isAnyRowChecked = true;
              rowData.push(hierarchyCounter.toString()); // Add hierarchy number
              hierarchyCounter++;
              cells.forEach(cell => {
                  rowData.push(toTitleCase(cell.textContent.trim())); // Convert data to Title Case
              });
              data.push(rowData);
          }
      }

      // If no row is checked, add all rows
      if (!isAnyRowChecked) {
          for (let i = 1; i < rows.length; i++) {
              const rowData = [];
              const cells = rows[i].querySelectorAll('td');
              rowData.push(hierarchyCounter.toString()); // Add hierarchy number
              hierarchyCounter++;
              cells.forEach(cell => {
                  rowData.push(toTitleCase(cell.textContent.trim()));
              });
              data.push(rowData);
          }
      }

      // Add the table to the PDF
      autoTable(pdf, {
          head: [headers],
          body: data,
          startY: 25, // Adjust position below title
          theme: 'striped', // Keep original striped theme
          styles: { fontSize: 10, cellPadding: 3 },
          headStyles: { fillColor: [0, 102, 204], textColor: [255, 255, 255], fontStyle: 'bold' ,
            cellWidth: 'wrap', // Set column 0 (Hierarchy) to wrap
            // Set all headers to wrap by iterating dynamically
            ...Object.fromEntries(headers.map((_, index) => [index, { cellWidth: 'wrap' }])),
          }, // Keep blue header
          alternateRowStyles: { fillColor: [230, 230, 230] }, // Keep light gray for alternate rows
          margin: { top: 20 }, // Adjust top margin
      });

      // Save the PDF
      pdf.save('Trippi_Orders_Report.pdf');
  } else {
      console.error('Table reference not initialized or element not found.');
  }
}


  singleToggle(event: any, index: number) {
    if( event.checked ) {
      this.checkboxStates[index].state = true;
    } else {
      this.checkboxStates[index].state = false;
    }
    this.collectEmailList();
    this.collectSmsList();
  }

  masterToggle(event: any) {
    if( event.checked ) {
      this.checkboxStates.forEach((item,index) => {
        this.checkboxStates[index].state = true;
      });
    } else {
      this.checkboxStates.forEach((item,index) => {
        this.checkboxStates[index].state = false;
      });
    }
    this.collectEmailList();
    this.collectSmsList();
  }
  
  collectEmailList() {
    let filteredEmails = [];
    for (let i = 0; i < this.checkboxStates.length; i++) {
        if (this.checkboxStates[i].state) {
            const emails: string[] = this.checkboxStates[i].data.contactDetail.email;
            filteredEmails.push(emails);
        }
    }
    this.emailList = filteredEmails;
  }

  collectSmsList() {
    let filteredPhone: string[] = [];
    for (let i = 0; i < this.checkboxStates.length; i++) {
        if (this.checkboxStates[i].state) {
            const number = this.checkboxStates[i].data.contactDetail.mobileNo;
            filteredPhone = filteredPhone.concat(number);
        }
    }
    this.phoneList = filteredPhone;
  }

  hasCheckedStates(): boolean {
    return this.checkboxStates.some(state => state.state);
  }

  sendSms() {
    if (this.hasCheckedStates()) {
      this.showSmsPopup = true;
    }
  }

  closeSmsPopup() {
    this.showSmsPopup = false;
  }

  sendEmail() {
    if (this.hasCheckedStates()) {
      this.showEmailPopup = true;
    }
  }

  closeEmailPopup() {
    this.showEmailPopup = false;

  }

  sentPushNotification() {
    this.showPushPopup = true;
  }

  closePushPopup() {
    this.showPushPopup = false;
  }

  eventDetail(order: any) {
    this.showEventDetail = true;
    this.currentOrder=order;
    // this.dashboardFacade.getEventDetail(id);
  }

  closeEventDetail() {
    this.showEventDetail = false;
  }

  flightDetail(order: any) {
    this.showFlightDetail = true;
    this.currentOrder=order;
  }

  closeFlightDetail() {
    this.showFlightDetail = false;
  }

  hotelDetail(order: any) {
    this.showHotelDetail = true;
    this.currentOrder=order;
  }

  closeHotelDetail() {
    this.showHotelDetail = false;
  }

  sendEmailNotification(event: any) {
    
    if( this.emailList.length > 0 ) {
      event = {
        ...event,
        emails: this.emailList
      }
      this.dashboardFacade.sendEmail(event);
    }
  }
  
  sendSmsNotification(event: any) {
    if( this.phoneList.length > 0 ) {
      event = {
        ...event,
        to: this.phoneList
      }
      this.dashboardFacade.sendSms(event);
    }
  }

  sendOrderComment(event:any){
    let Comment:OrderComment={
      orderId:this.currentOrder.orderId,
      comment:event.comment
    }
    this.dashboardFacade.updateOrderComment(Comment);
    this.closePopup();
    this.dashboardFacade.getOrders();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}