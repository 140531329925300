import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { SortValueEnum } from "src/app/libs/ui/components/top-bar/enums/top-bar.enum";

export const FlightsStateActions = createActionGroup({
  source: "State/API",
  events: {
    "Load Initial Flights Data": props<{ requestQuery: any }>(),
    "Load Initial Flights Data Success": props<{
      data: {
        flights: any;
        offerRequestId: string;
        sort: SortValueEnum;
        totalRecordsLeft: number;
        totalRecords: number;
      };
    }>(),
    "Load Initial Flights Data Failure": props<{ error: any }>(),
    "Load More Flights": props<{ requestQuery: any }>(), //TODO: create an interface and use it all over
    "Load More Flights Success": props<{
      data: { flights: any; totalRecordsLeft: number; totalRecords: number; };
    }>(),
    "Load More Flights Failure": props<{ error: any }>(),
    "Update Flight Sort Order": props<{ sortValue: SortValueEnum }>(),
    "Save Flight Filters Configuration": props<{ filtersMetadata: any }>(),
    "Load Flight Filter Options": props<{ requestQuery: any }>(),
    "Load Flight Filter Options Success": props<{ data: any }>(),
    "Load Flight Filter Options Failure": props<{ error: any }>(),
    'Set Quick Filter Flight': props<{ selectedQuickFilterFlight: string }>(),
  },
});
