import { Action, createReducer, on } from "@ngrx/store";
import { SortValueEnum } from "src/app/libs/ui/components/top-bar/enums/top-bar.enum";
import { FlightsState } from "../model";
import { FlightsStateActions } from "./flights.actions";
import { flightsAdapter } from "./flights.adapter";
import { InitialLoadActions, InitialLoadActionsWithData } from "src/app/libs/data-access/store/shared";
import { FilterType } from "src/app/libs/ui/enums/fiters.enums";
import { ResetState } from "src/app/actions/global.actions";

export const initialFlightsState: FlightsState = flightsAdapter.getInitialState(
  {
    entities: {},
    offerRequestId: "",
    afterCursor: "",
    filtersMetadata: InitialLoadActionsWithData,
    sort: SortValueEnum.TotalAmountAsc,
    requestMetadata: null,
    ids: [],
    totalRecordsLeft: -1,
    totalRecords: 0,
    selectedQuickFilterFlight: 'cheapest',
    loadMoreActions: InitialLoadActions,
    loading: false,
    loaded: false,
    error: null,
    active: [],
  selectedQuickFilter:{}
  }
);

export const FlightsStateFeatureKey = "FlightsStateFeatureKey";

export const flightsReducer = createReducer(
  initialFlightsState,
  on(FlightsStateActions.loadInitialFlightsData, (state, action) => {
    const { requestQuery } = action;
    const { sort } = action?.requestQuery?.flightsCriteria?.searchParams;
    return {
      ...state,
      sort,
      requestMetadata: requestQuery,
      loadMoreActions: InitialLoadActions,
      loading: true,
      loaded: false,
      error: null,
    };
  }),

  on(FlightsStateActions.loadInitialFlightsDataSuccess, (state, action) => {
    const { flights, offerRequestId, sort, totalRecordsLeft, totalRecords } = action.data;
    const active = flights;
    const updatedState = flightsAdapter.addMany(flights, state);

    return {
      ...updatedState,
      active,
      sort,
      totalRecordsLeft,
      totalRecords,
      offerRequestId,
      loading: false,
      loaded: true,
      error: null,
    };
  }),

  on(FlightsStateActions.loadInitialFlightsDataFailure, (state, action) => {
    //TODO: add errors
    const { error } = action;
    return {
      ...state,
      loading: false,
      loaded: true,
      error: error,
    };
  }),

  on(FlightsStateActions.loadMoreFlights, (state, action) => {
    return {
      ...state,
      loadMoreActions: {
        loading: true,
        loaded: false,
        error: null,
      },
    };
  }),

  on(FlightsStateActions.loadMoreFlightsSuccess, (state, action) => {
    const { flights, totalRecordsLeft, totalRecords } = action.data;
    const active = [...state.active, ...flights];
    const updatedState = flightsAdapter.addMany(flights, state);

    return {
      ...updatedState,
      active,
      totalRecordsLeft,
      totalRecords,
      loadMoreActions: {
        loading: false,
        loaded: true,
        error: null,
      },
    };
  }),

  on(FlightsStateActions.loadMoreFlightsFailure, (state, action) => {
    //TODO: add errors
    const { error } = action;

    return {
      ...state,
      loadMoreActions: {
        loading: false,
        loaded: true,
        error,
      },
    };
  }),

  on(FlightsStateActions.updateFlightSortOrder, (state, action) => {
    const { sortValue } = action || {};

    return {
      ...state,
      sort: sortValue,
    };
  }),

  on(FlightsStateActions.saveFlightFiltersConfiguration, (state, action) => {
    const { filtersMetadata } = action;
    return {
      ...state,
      filtersMetadata: {
        data: filtersMetadata,
        loading: false,
        loaded: false,
        error: null,
      },
    };
  }),

  on(FlightsStateActions.loadFlightFilterOptions, (state, action) => {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: null,
    };
  }),

  on(FlightsStateActions.loadFlightFilterOptionsSuccess, (state, action) => {
    const { filterOptions } = action?.data || {};
    const filtersMetadata = JSON.parse(JSON.stringify(state.filtersMetadata));
    for (let filter of filtersMetadata.data) {
      if (filter.type == FilterType.Slider) {
        if (filter.id == "price" || filter.id == "duration") {
          filter.options[0].min.defaultValue = filterOptions[filter.id].min;
          filter.options[0].max.defaultValue = filterOptions[filter.id].max;
        }
        if (filter.id == "inbound" || filter.id == "outbound") {
          filter.options.map((option: any) => {
            let filterVal: any = {};
            filterVal =
              filterOptions[filter.id][option.min.displayName.toLowerCase()];
            if (filterVal) {
              option.min.defaultValue = filterVal.min;
              option.max.defaultValue = filterVal.max;
            }
          });
        }
      }
      if (filter.type == FilterType.Checkbox) {
        for (let data of filterOptions[filter.id]) {
          filter.options[0].options.push(data);
        }
      }
    }

    return {
      ...state,
      filtersMetadata: {
        data: [ ...filtersMetadata.data ],
        loading: false,
        loaded: true,
        error: null,
      },
    };
  }),

  on(FlightsStateActions.loadFlightFilterOptionsFailure, (state, action) => {
    const { error } = action;
    return {
      ...state,
      filtersMetadata: {
        data: null,
        loading: false,
        loaded: true,
        error,
      },
    };
  }),
  on(FlightsStateActions.setQuickFilterFlight, (state, action) => {
    const { selectedQuickFilterFlight } = action || {};
    return { //This is a sync action so no need for loading and loaded...
      ...state,
      selectedQuickFilterFlight,
    };
  }),
  
  on(ResetState, (state:any,action:any) =>{
    
    return {
      ...initialFlightsState,
      filtersMetadata: {
        data: [],
        loading: false,
        loaded: false,
        error: null,
      },
    }
  }),
);

export function FlightsReducer(
  state: FlightsState | undefined,
  action: Action
) {
  return flightsReducer(state, action);
}
