<h3 class="font-weight-bold">{{'dashboard.orderDetailPage.title' | i18n}}</h3>

<div class="table-responsive" *ngIf="dashboardFacade.vm$ | async as vm">
    <div *ngIf="vm.loaded; else loader">
        <div class="wrapper" *ngIf="vm.orderDetail && isObjectNotEmpty(vm.orderDetail)">
            <div class="event-details" *ngIf="vm?.orderDetail?.ticketsDetail && vm?.orderDetail?.ticketsDetail.length > 0 ">
                <h4>{{'dashboard.orderDetailPage.eventSummary' | i18n}}</h4>
                <table class="event-table">
                    <thead>
                    <tr>
                        <th>{{'dashboard.orderDetailPage.eventId' | i18n}}</th>
                        <th>{{'dashboard.orderDetailPage.eventName' | i18n}}</th>
                    </tr>
                    </thead>
                    <tbody *ngFor="let events of vm?.orderDetail?.ticketsDetail">
                    <tr *ngFor="let event of events">
                        <td>{{ event.orderId }}</td>
                        <td>{{ event.name }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="flight-details" *ngIf="vm?.orderDetail?.flightDetail && isObjectNotEmpty(vm?.orderDetail?.flightDetail) ">
                <h4>{{'dashboard.orderDetailPage.flightSummary' | i18n}}</h4>
                <table class="event-table">
                    <thead>
                    <tr>
                        <th>{{'dashboard.orderDetailPage.offerId' | i18n}}</th>
                        <th>{{'dashboard.orderDetailPage.flightName' | i18n}}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr >
                        <td>{{ vm?.orderDetail?.flightDetail?.offer_id }}</td>
                        <td>{{ vm?.orderDetail?.flightDetail?.owner?.name }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="hotel" *ngIf="vm?.orderDetail?.hotelDetail && isObjectNotEmpty(vm?.orderDetail?.hotelDetail) ">
                <h4>{{'dashboard.orderDetailPage.hotelSummary' | i18n}}</h4>
                <table class="event-table">
                    <thead>
                    <tr>
                        <th>{{'dashboard.orderDetailPage.registrationNumber' | i18n}}</th>
                        <th>{{'dashboard.orderDetailPage.hotelName' | i18n}}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr >
                        <td>{{ vm?.orderDetail?.hotelDetail?.invoiceCompany?.registrationNumber }}</td>
                        <td>{{ vm?.orderDetail?.hotelDetail?.hotel?.name }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <ng-template #loader>
        <tic-loader></tic-loader>
    </ng-template>
</div>