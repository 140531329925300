import { LanguageObjectInterface } from "./interfaces/lang.interface";

const heI18n: LanguageObjectInterface = {
  homePage: {
    navBar: {
      discover: 'לְגַלוֹת',
      deals: 'עסקאות מיוחדות',
      community: 'קהילה',
      about: 'עלינו',
      viewOrders: 'הצג את ההזמנות שלי',
      ticketItems: {
        event: 'אירוע',
        flight: 'טִיסָה',
        hotel: 'מלון',
        checkout: 'תשלום',
        done: 'בוצע'
      }
    },
    homeBanner: {
      title: 'Find your best way to watch a game or a music event.',
      tagLine: 'Any Event. Anywhere. Anytime.',
      ticket: '# Tickets',
      booking: 'Booking +'
    },
    searchForm: {
      origin: '*מָקוֹר',
      city: '*עִיר',
      destination: '*יַעַד',
      selectDate: 'בחר את התאריך שלך',
      persons: '*אנשים',
      package: '*סוג חבילה',
      searchBtn: 'מצא את טיול הטריפי שלי',
      search: 'לחפש',
      adults: 'חדרים',
      rooms: 'מבוגרים /',
      child: 'יֶלֶד',
      next: "הַבָּא",
      done: "בוצע"
    },
    categorySection: {
      title: 'Category',
      catTagLine: 'We Offer Best Services',
      servicesList: {
        ticketBooking: {
          title: 'Ticket Booking',
          description: 'Lorem ipsum dolor sit amet consectetur. Eu id libero urna mi amet orci pulvinar natoque vulputate. Dictumst magna posuere proin'
        },
        sportsBooking: {
          title: 'Sports Booking',
          description: 'Lorem ipsum dolor sit amet consectetur. Eu id libero urna mi amet orci pulvinar natoque vulputate. Dictumst magna posuere proin'
        }
      }
    },
    bookingSection: {
      booking: 'BOOKING',
      title: 'Football ticket, travel and hotel packages',
      description: 'Experience the incomparable feeling of being united with thousands of'
        + 'other fans, all chanting, hoping and praying for the same outcome.Book'
        + 'your sports travel experience to watch some of the most exciting'
        + 'football games of the season.Choose your league below to explore our'
        + 'options for various clubs',
      bookTrip: 'Book your Trip'
    },
    hotelSection: {
      title: 'BEST HOTELS',
      hotelTagLine: 'Explore the top Hotels and Resorts'
    },
    eventSection: {
      title: 'Event',
      eventTagLine: 'Choose your event...'
    },
    testimonialSection: {
      title: 'Testimonial',
      testimonialTagLine: 'See happy Travelers',
    },
    howItWorks: {
      title: 'How it works',
      category: {
        tickets: {
          title: 'Biggest games',
          description: 'Lorem ipsum dolor sit amet consectetur. Elit massa sit in molestie elit.'
        },
        travelAcc: {
          title: 'Travel & accommodation',
          description: 'Lorem ipsum dolor sit amet consectetur. Elit massa sit in molestie elit.'
        },
        games: {
          title: 'Official tickets*',
          description: 'Lorem ipsum dolor sit amet consectetur. Elit massa sit in molestie elit.'
        }
      }
    }
  },  
  summaryCard:{
    events: 'אירועים',
    flights: 'טיסות',
    hotel: 'מלון',
    trripiPass: 'מעבר טריפי',
    ticketoryPass: 'כרטיס כרטיסים',
    dayPass: 'עוברים 4 ימים',
    perPerson: '/ לאדם',
    priceIncludes: 'המחיר כולל:',
    roundTripFlight: "טיסה הלוך ושוב מ",
    to: "ל",
    nightsIn: "לילות ב",
    packageInclude: 'אירוע + שהייה + טיסה + מעבר טריפי',
    note: '* שימו לב שהמחירים עדיין עשויים לעלות עקב'
      + 'זמינות עד להשלמת התשלום שלך.',
    continue: 'לְהַמשִׁיך',
    btnAddEvent: 'הוסף אירוע',
    btnAddLaggage: 'הוסף מזוודות',
    btnChangeFlight: 'שנה טיסות',
    totalCost: 'עלות כוללת',
    btnGoToCheckOut: 'עבור לקופה',
    packageType: {
      TicketFlightHotel: "כרטיס + טיסה + מלון",
      TicketFlight: "כרטיס + טיסה",
      TicketHotel: "כרטיס + מלון"
    },
    max: 'מקסימום',
    guests: 'אורחים',
    roomType: 'סוג חדר -',
    includedBeds: 'מיטות כלולות',
    singleBeds: '2 מיטות יחיד',
    fullScreen: "מסך מלא",
    flightTooltip: "נווט לדף הטיסה כדי להוסיף טיסות",
    eventTooltip: "נווט לדף האירוע כדי להוסיף אירועים"
  },
  formMetadata: {
    title: 'מממן את הדרך הטובה ביותר שלך לצפות במשחק עכשיו',
    destination: {
      fieldName: 'יעדים',
      optionsArray: [{ displayName: 'ניו יורק', value: 'אָדוּק' }, { displayName: 'סידני', value: 'סייד' }, { displayName: 'טיל או', value: 'הנשמה' }, { displayName: 'פקיסטן', value: 'טָהוֹר' }, { displayName: 'הוֹדוּ', value: 'IND' }],
      defaultValue: () => '',
    },
    origin: {
      fieldName: 'מָקוֹר',
      optionsArray: [{ displayName: 'ניו יורק', value: 'אָדוּק' }, { displayName: 'סידני', value: 'סייד' }, { displayName: 'טיל או', value: 'הנשמה' }, { displayName: 'פקיסטן', value: 'טָהוֹר' }, { displayName: 'הוֹדוּ', value: 'IND' }],
      defaultValue: () => '',
    },
    numberOfAdults: {
      fieldName: 'מבוגרים (מעל גיל 13)',
      optionsArray: [{ displayName: '1 מבוגר', value: 1 }],
      defaultValue: () => 1,
    },
    numberOfChildren: {
      fieldName: 'ילדים (מתחת לגיל 13)',
      optionsArray: [{ displayName: '0 ילדים', value: 0 }],
      defaultValue: () => 0,
    },
    numberOfRooms: {
      fieldName: 'persons',
      optionsArray: [{ displayName: 'חדר 1', value: 1 }],
      defaultValue: () => '',
    },
    dateRange: {
      label: 'הזן טווח תאריכים',
      // startDateLabel: 'Start Date',
      // endDateLabel: 'End Date',
    },
    submitButton: 'מצא את הטיול שלך',
  },
  sortOptions: {
    amount: 'הכמות הכוללת',
    duration: 'משך זמן כולל',
    ascending: 'עולה',
    descending: 'יורד',
  },
  flightsPage: {
    common: {
      title: 'בחר את הטיסה שלך',
      sortBy: 'מיין לפי:',
      showing: "מראה",
      outOf: "מִתוֹך",
      flightOptions:'אפשרויות טיסה',
      noFlightAvaiable: 'אין טיסות זמינות',
      outbound:'יוצא',
      inbound:'נכנסת',
    },   
      summaryCard:{
        events: 'אירועים',
        flights: 'טיסות',
        hotel: 'מלון',
        trripiPass: 'מעבר טריפי',
        dayPass: 'עוברים 4 ימים',
        perPerson: '/ לאדם',
        priceIncludes: 'המחיר כולל:',
        packageInclude: 'אירוע + שהייה + טיסה + מעבר טריפי',
        note: '* שימו לב שהמחירים עדיין עשויים לעלות עקב'
              +'זמינות עד להשלמת התשלום שלך.',
        continue: 'לְהַמשִׁיך',
      },   
    checkboxes: {
      stops: 'מפסיק',
      direct: 'ישיר',
      oneStop: 'עד 1',
      twoStops: 'עד ל 2',
      threeStops: 'עד ל 3',
    },
    sliders: {
      outbound: "טיסה יוצאת",
      inbound: "טיסה נכנסת",
      departure: "זמן יציאה",
      arrival: "זמן הגעה",
    },
    result: {
      stops: "מפסיק",
      journeyDuration: "משך המסע",
      connection: "חיבור",
      operatedBy: "מופעל על ידי",
      arrive: "מגיע",
      bookFlight: "הזמינו טיסה זו"
    }
  },
  checkoutPage: {
    formFields: {
      firstName: {
        name: "שם פרטי",
        errorMessage: "עליך להזין את השם הפרטי והאמצעי שלך",
        requiredMessage: "עליך להזין את שמך הפרטי"
      },
      lastName: {
        name: "שם משפחה",
        errorMessage: "עליך להזין את שם המשפחה שלך",
        requiredMessage: 'עליך להזין את שם המשפחה והאמצעי שלך'
      },
      email: {
        name: "אימייל",
        errorMessage: "אנא הזן כתובת דוא"+"ל תקנית",
        requiredMessage: "עליך להזין את המייל שלך"
      },
      phoneNumber: {
        name: "מספר טלפון",
        errorMessage: "נא להזין מספר נייד חוקי (אסור להתחיל באפס)",
        requiredMessage: "עליך להזין את מספר הנייד שלך"
      },
      passportNumber: {
        name: "מספר דרכון",
        errorMessage: "נא להזין מספר דרכון תקף.",
        requiredMessage: "עליך להזין את מספר הדרכון שלך",
      },
      passportExpirationDate: {
        name: "תאריך תפוגת הדרכון",
        errorMessage: "נא להזין תאריך תפוגה של דרכון בתוקף.",
        requiredMessage: "עליך להזין את מספר הדרכון שלך"
      },
      adult: {
        name: "מְבוּגָר",
        errorMessage: "לא מבוגר",
        requiredMessage: ""
      },
      child: {
        name: "יֶלֶד",
        errorMessage: "נא למלא תאריך לידת ילד.",
        requiredMessage: ""
      },
      infant: {
        name: "תִינוֹק",
        errorMessage: "נא למלא תאריך לידת תינוק.",
        requiredMessage: ""
      },
      birthDate: {
        name: "תאריך לידה",
        errorMessage: "נא למלא תאריך לידה חוקי.",
        requiredMessage: "עליך לבחור את תאריך הלידה שלך"
      },
      gender: {
        male: "זָכָר",
        female: "נְקֵבָה",
        errorMessage: "אנא בחר מין."
      },
      space: " ",
      passengerType: {
        name: "סוּג",
        errorMessage: "נא למלא תאריך לידה חוקי.",
        requiredMessage: "עליך לבחור סוג נוסע"
      },
      nationality: {
        name: "לאום",
        errorMessage: "",
        requiredMessage: "עליך לבחור את הלאום שלך",
      },
      city: {
        name: "עיר לידה",
        errorMessage: "",
        requiredMessage: "עליך לבחור את העיר שלך",
      }
    },
    titles: {
      contactDetails: "פרטי יצירת קשר",
      nationality: "לאום",
      submit: "שלח",
    }
  },
  hotelsPage: {
    common: {
      title: 'בחר את הטיסה שלך',
      sortBy: 'מיין לפי:',
      paginationText: 'מציג 5 מתוך 5 אפשרויות מלונות',
      noHotelAvaiable: 'אין מלונות פנויים',
      showing: "מראה",
      outOf: "מִתוֹך",
      HotelOptions: "אפשרויות מלונות",
      btnViewHotel: "מלון צפייה"
    },  
    hotelDetailsPage: {
      btnBookNow: "להזמין עכשיו",
      btnViewPhotos:'צפה בכל התמונות',
      btnRoomView: "נוף לחדר",
      btnSelectRoom: "בחר חדר",
      overview: "סקירה כללית",
      description:'תיאור',
      stayStructure:'מבנה להישאר', 
      facilities:'מתקנים',
      rooms: "חדרים",
      address: "כתובת",
      btnViewMap: "צפה בגוגל מפות",
      amenities: "שירותים"
    },
      summaryCard:{
        events: 'אירועים',
        flights: 'טיסות',
        hotel: 'מלון',
        trripiPass: 'מעבר טריפי',
        dayPass: 'עוברים 4 ימים',
        perPerson: '/ לאדם',
        priceIncludes: 'המחיר כולל:',
        packageInclude: 'אירוע + שהייה + טיסה + מעבר טריפי',
        note: '* שימו לב שהמחירים עדיין עשויים לעלות עקב'
              +'זמינות עד להשלמת התשלום שלך.',
        continue: 'לְהַמשִׁיך',
      },  
    allHotelsPage: null,
    singleHotelPage: {
      description: 'תיאור',
      address: 'כתובת',
      rating: 'דֵרוּג',
      rooms_options: 'אפשרויות חדרים',
    }
  },
  eventsPage: {
    checkboxes: {
      eventType: 'אירועים',
      eventSubType: 'טורנירים',
      location: 'מיקומים',      
    },
    sliders: {
      price: 'מחיר',
    },
    common: {
      title: 'חקור אירועים',
      sortBy: 'מיין לפי:',
      showing: "מראה",
      outOf: "מִתוֹך",
      eventOptions: "אפשרויות אירוע",
      vs: 'לעומת',
      addMoreText: 'הוסף אירועים נוספים לטיול שלך...',
      all:'את כל'
    },
      quickFilters: {
        arts: 'אומנויות',
        bussiness: 'עֵסֶק',
        concert: 'קוֹנצֶרט',
        sport: 'ספורט'
      },     
      summaryCard:{
        events: 'אירועים',
        flights: 'טיסות',
        hotel: 'מלון',
        trripiPass: 'מעבר טריפי',
        dayPass: 'עוברים 4 ימים',
        perPerson: '/ לאדם',
        priceIncludes: 'המחיר כולל:',
        packageInclude: 'אירוע + שהייה + טיסה + מעבר טריפי',
        note: '* שימו לב שהמחירים עדיין עשויים לעלות עקב'
              +'זמינות עד להשלמת התשלום שלך.',
        continue: 'לְהַמשִׁיך',
      },    
      selectSeat:{
        selectSeat:'בחר מושב',
        selectSeats:'בחר מושבים',
        editTickets:'ערוך כרטיסים',
        message:'עליך לבחור לפחות מושב אחד ולכל היותר',
        seat:'מושב',
        seats:'מקומות ישיבה',
        continue:'לְהַמשִׁיך'
      }
  },
  tripCheckoutPage:{
    contacDetails: 'פרטי יצירת קשר',
    noteContact: 'ניצור איתך קשר רק לעדכונים חשובים לגבי הטיול שלך.',
    passengerDetails: 'פרטי נוסע',
    notePassenger: 'כל שם צריך להופיע בדיוק כמו בדרכון.',
    haveCode: 'יש לך קוד?',
    btnApply: 'להגיש מועמדות',
    waysToPay: 'דרכים לשלם',
    finalPayment: 'תשלום סופי',
    btnPay: 'לְשַׁלֵם',
    btnProcessing: 'מעבד...',
    summaryCard: {
      tripSummary: 'סיכום טיול',
      flyingFrom: 'עף מ',
      resort: 'אתר נופש',
      from: 'מ',
      to: 'ל',
      groupSize: 'גודל קבוצה',
      member: 'חָבֵר',
      members: 'חברים',
      priceDetail: 'פרטי מחיר',
      pakage: 'חֲבִילָה',
      eventInclusive: '(כולל אירועים)',
      hotel: 'מלון (נוסף)',
      flight: 'טיסה (נוסף)',
      bookingFee: 'דמי הזמנה',
      total: 'סה"כ',
      orderMessageSuccessA: "הזמנת הטריפי שלך טריפי-",
      orderMessageSuccessB: "הושלם בהצלחה!",
      orderNote: "אנחנו לא יכולים לחכות לארח אותך באירוע, היה סבלני",
      continueBtn: "לְהַמשִׁיך"
    },
    firstName: "שם פרטי",
    lastName: "שם משפחה",
    mobileNumber: "מס' נייד",
    email: "אימייל",
    firstMiddleName: "שם פרטי ושם משפחה",
    dob: "תאריך לידה",
    nationality: "לאום",
    city: "עיר לידה",
    passportNumber: "מספר דרכון",
    couponLabel: "הזן את הקוד שלך כאן",
    valid: 'הוחל קופון',
    invalid: 'הקופון לא חוקי',
    secureCheckout: "יציאה מאובטחת",
    secureCheckoutNote: "העסקה שלך מאובטחת במלואה עם הצפנה מתקדמת. היה סמוך ובטוח, המידע הרגיש שלך נשאר בטוח וסודי.",
    payFull: "שלם את מלוא",
    payFullNote: "שלם את מלוא הסכום עכשיו והכל מוכן.",
    pay35: "שלם פיקדון של 35% עכשיו, והשאר מאוחר יותר.",
    pay35Note: "שלם עכשיו פיקדון קטן והשלם את השאר עד 90 יום לפני הנסיעה שלך.",
    remainingPay: "יתרת יתר לאחר התשלום:",
    cardNumber: "מספר כרטיס",
    expiration: "תפוגה",
    btnApplying: "מגיש בקשה..."
  },
  footer: {
    destination: {
      title:'יעדים',
      contry: 'מדינות/טריטוריות',
      city: 'ערים'
    },
    company: {
      title:'חֶברָה',
      about: 'עלינו',
      career: 'קריירה',
      press: 'ללחוץ',
      blog: 'בלוג',
      points: 'נקודותמקסימום'
    },
    help: {
      title:'עֶזרָה',
      center: 'מרכז עזרה',
      faq: 'שאלות נפוצות',
      privacyPolicy: 'מדיניות הפרטיות',
      cookiePolicy: 'מדיניות קובצי עוגייה',
      terms: 'תנאי שימוש',
      cookieSetting: 'נהל את הגדרות העוגיות'
    },
    trripi: {
      descrption: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.'
    },
    copyright:'© זכויות יוצרים 2023, יצירה על ידי UX-flow Infotech',
  },
  filters:{
    title: 'מסננים',
    clearFilters: 'נקה מסננים',
    cheapest: 'הזול ביותר',
    musicShows: 'מוזיקה ומופעים',
    sportsEvent: 'אירועי ספורט',
    fastest: 'הכי מהיר',
    best: 'הטוב ביותר',
    closestCenter: 'המרכז הקרוב ביותר',
    closestFirstEvent: 'הכי קרוב לאירוע הראשון'
  },
   viewOrder:{
     btnText: 'הצג את הזמנת הטריפי שלי',
     idInputText: 'מספר/מזהה הזמנה:',
     emailInputText: 'אימייל ליצירת קשר הזמנה:',
     validatorMessage: {
       idValid: "מזהה ההזמנה צריך להיות בן 8 ספרות",
       emailValid: "כתובת אימייל לא חוקית",
       space: " ",
       idRequired: "עליך להזין מזהה הזמנה",
       emailRequired: "עליך להזין את המייל"
     }
   },
  dashboard: {
    login: {
      heading: 'כניסה למשתמש',
      subHeading: 'ברוכים הבאים לאתר',
      validationUser: 'נדרש שם משתמש.',
      validationPassword: 'נדרשת סיסמה.',
    },
    buttons: {
      csv: 'ייצוא ל-CSV',
      pdf: 'ייצוא ל-PDF',
      sms: 'שלח מסרון',
      email: 'שלח אימייל',
      push: 'שלח הודעת דחיפה',
      saveView: 'שמור תצוגה',
      send: "לִשְׁלוֹחַ",
      cancel: "לְבַטֵל",
      createCoupon: 'הוסף קופון',
      logout: "להתנתק",
      searchLabel: "לחפש",
      couponSave: "להציל",
      couponUpdate: "עדכון"
    },
    sideNav: {
      home: "בית",
      allTrip: "כל הטיולים",
      orderDetail: "פרטי הזמנה",
      events: "אירועים",
      flights: "טיסות",
      hotels: "בתי מלון",
      reservation: "הזמנה",
      coupon: "קוּפּוֹן"
    },
    allTripPage: {
      welcomeTitle: "ברוך הבא טריפי",
      orderTable: {
        orderId: "מספר הזמנה",
        events: "אירועים",
        flights: "טיסות",
        hotels: "בתי מלון",
        status: "סטָטוּס",
        comments: "הערות"
      }
    },
    orderDetailPage: {
      title: "פרטי הזמנה",
      eventSummary: "תקציר האירוע:",
      eventId: "מזהה אירוע",
      eventName: "שם אירוע",
      flightSummary: "סיכום טיסה:",
      offerId: "מזהה הצעה",
      flightName: "שם הטיסה",
      hotelSummary: "סיכום מלון:",
      registrationNumber: "מספר רישום",
      hotelName: "שם המלון",
      selectView:"בחר תצוגה"
    },
    orderViewPage: {
      title: "תצוגת הזמנה",
      packageType: "סוג האריזה",
      noOfReservation: "מספר האנשים בהזמנה",
      orders: "הזמנות",
      sendSms: {
        title: "שלח הודעת SMS",
        sentMessage: "הודעת SMS נשלחה.",
        failedMessage: "שליחת SMS נכשלה.",
        numberValidatorMessage: "יש לבחור הזמנה כדי לשלוח הודעת SMS.",
        smsPlaceHolder: "הוֹדָעָה:"
      },
      sendEmail: {
        title: "שלח הודעת אימייל",
        sentEmail: "הדוא"+"ל נשלח.",
        failedEmail: "שליחת האימייל נכשלה.",
        numberValidatorEmail: "יש לבחור הזמנה כדי לשלוח הודעת דוא"+"ל.",
        emailPlaceHolder: "נושא:",
      },
      sendPushNotification: {
        title: "שלח הודעת דחיפה",
        sentNotification: "הודעת דחיפה נשלחה.",
        failedNotification: "הודעת דחיפה נכשלה",
        notificationPlaceHolderTitle: "כותרת:",
        notificationPlaceHolderBody: "גוּף:"
      }
    },
    filters: {
      dateFilter: {
        label: "תַאֲרִיך",
        none: "אף אחד",
        today: "היום",
        yesterday: "אתמול",
        last7Days: "7 ימים אחרונים",
        last30Days: "30 הימים האחרונים",
        thisMonth: "החודש",
        lastMonth: "חודש שעבר",
        custom: "המותאם אישית",
        startDate: "תאריך התחלה",
        endDate: "תאריך סיום"
      },
      statusFilter: {
        label: "סטָטוּס",
        none: "אף אחד",
        pending: "ממתין ל",
        ready: "מוּכָן",
        inProgress: "בתהליך",
        completed: "הושלם",
        cancelled: "מבוטל"
      },
      priceFilter: {
        label: "מחיר",
        none: "אף אחד",
        originPrice: "מחיר מקור",
        ourPrice: "המחיר שלנו",
        profit: "רווח"
      },
      chartOptions: {
        label: "סוג תרשים",
        barChart: "טבלת עמודות",
        linechart: "תרשים קווים",
        cakeChart: "תרשים עוגה"
      }
    },
    flightsPage: {
      title: "פרטי טיסה",
      incomeFromFlights: "הכנסה מטיסות",
      airlines: "חברות תעופה",
      profitOfFlights: "רווח של טיסות",
      outcomeOfFlights: "תוצאות טיסות"
    },
    hotelsPage: {
      title: "פירוט המלון",
      incomeFromHotels: "הכנסה ממלונות",
      hotels: "בתי מלון",
      profitOfHotels: "רווח של בתי מלון",
      outcomeOfHotels: "תוצאות בתי מלון"
    },
    eventsPage: {
      title: "פירוט האירועים",
      incomeFromEvents: "הכנסה מאירועים",
      events: "אירועים",
      profitOfEvents: "רווח של אירועים",
      outcomeOfEvents: "תוצאות אירועים"
    },
    reservationPage: {
      title: "פירוט ההזמנה",
      incomeFromReservations: "תוצאת ההזמנה",
      outcomeOfReservations: "הכנסה מהזמנה"
    },
    couponsPage: {
      title: "קופונים",
      couponCard: {
        active: "פָּעִיל",
        inActive: "לֹא פָּעִיל",
        code:"קוד:"
      },
      couponForm: {
        addTitle: "הוסף פרטי קופון",
        editTitle: "ערוך את פרטי הקופון",
        couponCode: {
          name: "קוד קופון",
          errorMessage: "",
          requiredMessage: "עליך להזין את קוד הקופון שלך"
        },
        couponName: {
          name: "שם הקופון",
          errorMessage: "",
          requiredMessage: "עליך להזין את שם הקופון שלך"
        },
        couponValue: {
          name: "ערך קופון",
          errorMessage: "",
          requiredMessage: "עליך להזין את קוד הקופון שלך"
        },
        discountType: {
          name: "סוג הנחה",
          errorMessage: "",
          requiredMessage: "עליך להזין את שם הקופון שלך"
        },
        fixed: "תוקן",
        percentage: "אֲחוּזִים",
        selectPlaceholderDiscount: "בחר סוג הנחה",
        usage: {
          name: "נוֹהָג",
          errorMessage: "השימוש חייב להיות מספר חוקי",
          requiredMessage: "עליך להזין שימוש"
        },
        startDateControl: {
          name: "תַאֲרִיך",
          errorMessage: "",
          requiredMessage: "עליך לבחור תאריך התחלה"
        },
        endDateControl: {
          name: "תַאֲרִיך",
          errorMessage: "",
          requiredMessage: "עליך לבחור תאריך סיום"
        },
        statusType: {
          name: "סוג סטטוס",
          errorMessage: "",
          requiredMessage: "עליך להזין את שם הקופון שלך"
        },
        currency: {
          name: "מַטְבֵּעַ",
          errorMessage: "",
          requiredMessage: "עליך לבחור את המטבע שלך"
        },
        notSelected: "לא נבחר",
        selectPlaceholderStatus: "בחר סטטוס קופון",
        startDate: "תאריך התחלה",
        endDate: "תאריך סיום",
        comment: {
          name: "תגובה",
          errorMessage: "",
          requiredMessage: "נדרש טקסט הערה"
        }
      }
    }
  }

};

export default heI18n;
  