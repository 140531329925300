import { LanguageObjectInterface } from "./interfaces/lang.interface";

const enI18n: LanguageObjectInterface = {
  homePage: {
    navBar: {
      discover: 'Discover',
      deals: 'Special Deals',
      community: 'Community',
      about: 'About Us',
      viewOrders: 'View My Orders',
      ticketItems: {
        event: 'Event',
        flight: 'Flight',
        hotel: 'Hotel',
        checkout: 'Checkout',
        done: 'Done'
      }
    },
    homeBanner: {
      title: 'Find your best way to watch a game or a music event.',
      tagLine: 'Any Event. Anywhere. Anytime.',
      ticket: '# Tickets',
      booking: 'Booking +'
    },
    searchForm: {
      origin: 'Origin*',
      city: 'City*',
      destination: 'Destination*',
      selectDate: 'Select your date',
      persons: 'Persons*',
      package: 'Package Type*',
      searchBtn: 'Find my Trripi Trip',
      search: 'Search',
      adults: 'Adult',
      rooms: 'Rooms /',
      child: 'Child',
      next: "Next",
      done: "Done"
    },
    categorySection: {
      title: 'Category',
      catTagLine: 'We Offer Best Services',
      servicesList: {
        ticketBooking: {
          title: 'Ticket Booking',
          description: 'Lorem ipsum dolor sit amet consectetur. Eu id libero urna mi amet orci pulvinar natoque vulputate. Dictumst magna posuere proin'
        },
        sportsBooking: {
          title: 'Sports Booking',
          description: 'Lorem ipsum dolor sit amet consectetur. Eu id libero urna mi amet orci pulvinar natoque vulputate. Dictumst magna posuere proin'
        }
      }
    },
    bookingSection: {
      booking: 'BOOKING',
      title: 'Football ticket, travel and hotel packages',
      description: 'Experience the incomparable feeling of being united with thousands of'
        + 'other fans, all chanting, hoping and praying for the same outcome.Book'
        + 'your sports travel experience to watch some of the most exciting'
        + 'football games of the season.Choose your league below to explore our'
        + 'options for various clubs',
      bookTrip: 'Book your Trip'
    },
    hotelSection: {
      title: 'BEST HOTELS',
      hotelTagLine: 'Explore the top Hotels and Resorts'
    },
    eventSection: {
      title: 'Event',
      eventTagLine: 'Choose your event...'
    },
    testimonialSection: {
      title: 'Testimonial',
      testimonialTagLine: 'See happy Travelers',
    },
    howItWorks: {
      title: 'How it works',
      category: {
        tickets: {
          title: 'Biggest games',
          description: 'Lorem ipsum dolor sit amet consectetur. Elit massa sit in molestie elit.'
        },
        travelAcc: {
          title: 'Travel & accommodation',
          description: 'Lorem ipsum dolor sit amet consectetur. Elit massa sit in molestie elit.'
        },
        games: {
          title: 'Official tickets*',
          description: 'Lorem ipsum dolor sit amet consectetur. Elit massa sit in molestie elit.'
        }
      }
    }
  },
  summaryCard:{
    events: 'Events',
    flights: 'Flights',
    hotel: 'Hotel',
    trripiPass: 'Trripi pass',
    ticketoryPass: 'Ticketory pass',
    dayPass: '4 days pass',
    perPerson: '/ per person',
    priceIncludes: 'Price includes:',
    packageInclude: 'Event + Stay + Flight + Trripi pass',
    note: '* Please note that prices may still increase due to '
      + 'availability until your payment is complete.',
    continue: 'Continue',
    roundTripFlight: "Round-Trip flight from",
    to: 'to',
    nightsIn: "nights in",
    btnAddEvent: 'Add Event',
    btnAddLaggage: 'Add Luggage',
    btnChangeFlight: 'Change Flights',
    totalCost: 'Total Cost',
    btnGoToCheckOut: 'Go To Checkout',
    packageType: {
      TicketFlightHotel: "Ticket + Flight + Hotel",
      TicketFlight: "Ticket + Flight",
      TicketHotel: "Ticket + Hotel"
    },
    max: 'Max',
    guests: 'Guests',
    roomType: 'Room Type -',
    includedBeds: 'Included beds',
    singleBeds: '2 Single beds',
    fullScreen: "Full Screen",
    flightTooltip: "Navigate to flight page to add flights",
    eventTooltip: "Navigate to event page to add events"
  },
  formMetadata: {
    title: 'Find Your Best way to watch a game anywhere',
    destination: {
      fieldName: 'destinations',
      optionsArray: [{ displayName: 'New York', value: 'NYC' },{ displayName: 'Sydney', value: 'SYD' }, { displayName: 'Tel Aviv', value: 'TLV' },{ displayName: 'India', value: 'IND' }],
      defaultValue: () => '',
    },
    origin: {
      fieldName: 'origin',
      optionsArray: [{ displayName: 'Sydney', value: 'SYD' }, { displayName: 'Tel Aviv', value: 'TLV' },{ displayName: 'India', value: 'IND' }],
      defaultValue: () => '',
    },
    numberOfAdults: {
      fieldName: 'adults (above 13y)',
      optionsArray: [{ displayName: '1 Adult', value: 1 }],
      defaultValue: () => 1,
    },
    numberOfChildren: {
      fieldName: 'children (under 13y)',
      optionsArray: [{ displayName: '0 Children', value: 0 }],
      defaultValue: () => 0,
    },
    numberOfRooms: {
      fieldName: 'persons',
      optionsArray: [{ displayName: '1 room', value: 1 }],
      defaultValue: () => '',
    },
    dateRange: {
      label: 'Enter a date range',
      // startDateLabel: 'Start Date',
      // endDateLabel: 'End Date',
    },
    submitButton: 'Find Your Trip',
  },
  sortOptions: {
    amount: 'Total Amount',
    duration: 'Total Duration',
    ascending: 'Ascending',
    descending: 'Descending',
  },
  flightsPage: {
    common:{
      title: 'Choose your flight',
      sortBy: 'Sort by:',
      showing: "Showing",
      outOf: "out of",
      flightOptions: "Flight options",
      noFlightAvaiable: 'No Flights Avaliable',
      outbound:'Outbound',
      inbound:'Inbound',
    },
      summaryCard:{
        events: 'Events',
        flights: 'Flights',
        hotel: 'Hotel',
        trripiPass: 'Trripi pass',
        dayPass: '4 days pass',
        perPerson: '/ per person',
        priceIncludes: 'Price includes:',
        packageInclude: 'Event + Stay + Flight + Trripi pass',
        note: '* Please note that prices may still increase due to '
        +'availability until your payment is complete.',
        continue: 'Continue',
      },
    checkboxes: {
      stops: 'Stops',
      direct: 'Direct',
      oneStop: 'Up To 1',
      twoStops: 'Up To 2',
      threeStops: 'Up To 3',
    },
    sliders: {
      outbound: "Outbound Flight",
      inbound: "Inbound Flight",
      departure: "Departure Time",
      arrival: "Arrival Time",
    },
    result: {
      stops: "STOP",
      journeyDuration: "Journey duration",
      connection: "Connection",
      operatedBy: "Operated by",
      arrive: "Arrives",
      bookFlight: "Book this flight"
    }
  },
  checkoutPage: {
    formFields: {
      firstName: {
        name: "First Name",
        errorMessage: "You must enter your first & middle name",
        requiredMessage: "You must enter your first name"
      },
      lastName: {
        name: "Last Name",
        errorMessage: "You must enter your last name",
        requiredMessage: "You must enter your last & middle name"
      },
      email: {
        name: "Email",
        errorMessage: "Please enter a valid email address",
        requiredMessage: "You must enter your email"
      },
      phoneNumber: {
        name: "Phone Number",
        errorMessage: "please enter a valid mobile  no.(must not start with zero)",
        requiredMessage: "You must enter your mobile no"
      },
      passportNumber: {
        name: "Passport Number",
        errorMessage: "Please enter a valid passport number.",
        requiredMessage: "You must enter your passport number",
      },
      passportExpirationDate: {
        name: "Passport Expiration Date",
        errorMessage: "Please enter a valid passport expiration date.",
        requiredMessage: "You must enter your passport number"
      },
      adult: {
        name: "Adult",
        errorMessage: "Not an adult",
        requiredMessage: ""
      },
      child: {
        name: "Child",
        errorMessage: "Please fill a child birth date.",
        requiredMessage: ""
      },
      infant: {
        name: "Infant",
        errorMessage: "Please fill an infant birth date.",
        requiredMessage: ""
      },
      birthDate: {
        name: "Birth Date",
        errorMessage: "Please fill a valid birth date.",
        requiredMessage: "You must select your date of birth"
      },
      gender: {
        male: "Male",
        female: "Female",
        errorMessage: "Please select a gender."
      },
      space: " ",
      passengerType: {
        name: "Type",
        errorMessage: "Please fill a valid birth date.",
        requiredMessage: "You must select passenger type"
      },
      nationality: {
        name: "Nationality",
        errorMessage: "",
        requiredMessage: "You must select your nationality",
      }
    },
    titles: {
      contactDetails: "Contact Details",
      nationality: "Nationality",
      submit: "Submit",
    }
  },
  hotelsPage: {
    common: {
      title: 'Choose your Hotel',
      sortBy: 'Sort by:',
      paginationText: 'Showing 5 out of 5 Hotels options',
      noHotelAvaiable: 'No Hotels Avaliable',
      showing: "Showing",
      outOf: "out of",
      HotelOptions: "Hotel options",
      btnViewHotel: "View Hotel"
    },
    summaryCard: {
      events: 'Events',
      flights: 'Flights',
      hotel: 'Hotel',
      trripiPass: 'Trripi pass',
      dayPass: '4 days pass',
      perPerson: '/ per person',
      priceIncludes: 'Price includes:',
      packageInclude: 'Event + Stay + Flight + Trripi pass',
      note: '* Please note that prices may still increase due to '
        + 'availability until your payment is complete.',
      continue: 'Continue',
    },
    allHotelsPage: null,
    singleHotelPage: {
      description: 'Description',
      address: 'Address',
      rating: 'Rating',
      rooms_options: 'Rooms Options',
    },
    hotelDetailsPage: {
      btnBookNow: "Book Now",
      btnViewPhotos: 'View all photos',
      btnRoomView: "Room View",
      btnSelectRoom: "Select Room",
      overview: "Overview",
      description: 'Description',
      stayStructure: 'Stay structure',
      facilities: 'Facilities',
      rooms: "Rooms",
      address: "Address",
      btnViewMap: "View on google maps",
      amenities: "Amenities",
    }
  },
  eventsPage: {
    common:{
      title: 'Explore Events',
      sortBy: 'Sort by:',
      showing: "Showing",
      outOf: "out of",
      eventOptions: "Event options",
      vs: 'Vs',
      addMoreText: 'Add more events to your trip…',
      all:'All'
    },  
      quickFilters: {
        arts: 'Arts',
        bussiness: 'Business',
        concert: 'Concert',
        sport: 'Sports'
      },
      summaryCard:{
        events: 'Events',
        flights: 'Flights',
        hotel: 'Hotel',
        trripiPass: 'Trripi pass',
        dayPass: '4 days pass',
        perPerson: '/ per person',
        priceIncludes: 'Price includes:',
        packageInclude: 'Event + Stay + Flight + Trripi pass',
        note: '* Please note that prices may still increase due to '
        +'availability until your payment is complete.',
        continue: 'Continue',
      },
    checkboxes: {
      eventType: 'Events',
      eventSubType: 'Tournaments',
      location: 'Locations',
    },
    sliders: {
      price: 'Price',
    },
    selectSeat:{
      selectSeat:'Select Seat',
      selectSeats:'Select Seats',
      editTickets:'Edit Tickets',
      message:'You must select at least one seat and at most',
      seat:'seat',
      seats:'seats',
      continue:'Continue'
    }
  },
  tripCheckoutPage:{
    contacDetails: 'Contact details',
    noteContact: 'We\'ll only contact you for important updates about your trip.',
    passengerDetails: 'Passenger details',
    notePassenger: 'Each name has to appear exactly like in the passport.',
    haveCode: 'Have a code?',
    btnApply: 'Apply',
    waysToPay: 'Ways to pay',
    finalPayment: 'Final Payment',
    btnPay: 'Pay',
    btnProcessing: 'Processing...',
    summaryCard: {
      tripSummary: 'Trip summary',
      flyingFrom: 'Flying From',
      resort: 'Resort',
      from: 'From',
      to: 'To',
      groupSize: 'Group size',
      members: 'members',
      priceDetail: 'Price details',
      pakage: 'Package',
      eventInclusive: '(Events Inclusive)',
      hotel: 'Hotel (Additional)',
      flight: 'Flight (Additional)',
      bookingFee: 'Booking Fee',
      total: 'Total',
      orderMessageSuccessA: "Your Trripi Order trripi-",
      orderMessageSuccessB: "is being completed Successfully!",
      orderNote: "We can't wait to host you in the event, Be patient",
      continueBtn: "Continue"
    },
    firstName: "First name",
    lastName: "Last name",
    mobileNumber: "Mobile no.",
    email: "Email",
    firstMiddleName: "First and middle name",
    dob: "Date of birth",
    nationality: "Nationality",
    passportNumber: "Passport number",
    couponLabel: "Enter your code here",
    valid: 'Coupon applied',
    invalid: 'Coupon is invalid',
    secureCheckout: "Secure checkout",
    secureCheckoutNote: "Your transaction is fully secured with advanced encryption. Rest assured, your sensitive information stays safe and confidential.",
    payFull: "Pay the full",
    payFullNote: "Pay the full amount now and you're all set",
    pay35: "Pay 35% deposit now, and the rest later",
    pay35Note: "Pay a small deposit now and complete the rest up to 90 days before your trip.",
    remainingPay: "Remaining balance after payment:",
    cardNumber: "Card Number",
    expiration: "Expiration",
    btnApplying: "Applying..."
  },
  footer: {
    destination: {
      title:'Destinations',
      contry: 'Countries/Territories',
      city: 'Cities'
    },
    company: {
      title:'Company',
      about: 'About us',
      career: 'Careers',
      press: 'Press',
      blog: 'Blog',
      points: 'PointsMAX'
    },
    help: {
      title:'Help',
      center: 'Help center',
      faq: 'FAQs',
      privacyPolicy: 'Privacy policy',
      cookiePolicy: 'Cookie policy',
      terms: 'Terms of use',
      cookieSetting: 'Manage cookie settings'
    },
    trripi: {
      descrption: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.'
    },
    copyright:'© Copyright 2023, Create by UX-flow Infotech',

  },
  filters:{
    title: 'Filters',
    clearFilters: 'Clear Filters',
    cheapest: 'Cheapest',
    musicShows: 'Music & Shows',
    sportsEvent: 'Sports Event',
    fastest: 'Fastest',
    best: 'Best',
    closestCenter: 'Closest Center',
    closestFirstEvent: 'Closest to first event'
  },
   viewOrder:{
     btnText: 'View My Trripi Order',
     idInputText: 'Order Number/Id:',
     emailInputText: 'Order Contact Email.',
     validatorMessage: {
       idValid: "Order ID should be 8 digits long",
       emailValid: "invalid email address",
       space: " ",
       idRequired: "You must enter order id",
       emailRequired: "You must enter the email"
     }
   },
  dashboard: {
    buttons: {
      csv: 'Export to CSV',
      pdf: 'Export to PDF',
      sms: 'Send Sms',
      email: 'Send Email',
      push: 'Send Push Notification',
      saveView: 'Save View',
      send: "Send",
      cancel: "Cancel",
      createCoupon: 'Add Coupon',
      logout: "Logout",
      searchLabel: "Search",
      couponSave: "Save",
      couponUpdate: "Update",
    },
    sideNav: {
      home: "Home",
      allTrip: "All Trips",
      orderDetail: "Order Details",
      events: "Events",
      flights: "Flights",
      hotels: "Hotels",
      reservation: "Reservation",
      coupon: "Coupon"
    },
    allTripPage: {
      welcomeTitle: "Welcome Trripi",
      orderTable: {
        orderId: "Order Id",
        events: "Events",
        flights: "Flights",
        hotels: "Hotels",
        status: "Status",
        comments: "Comments"
      }
    },
    orderDetailPage: {
      title: "Order Details",
      eventSummary: "Event Summary:",
      eventId: "Event ID",
      eventName: "Event Name",
      flightSummary: "Flight Summary:",
      offerId: "Offer ID",
      flightName: "Flight Name",
      hotelSummary: "Hotel Summary:",
      registrationNumber: "Registration Number",
      hotelName: "Hotel Name",
      selectView: "Select view"
    },
    orderViewPage: {
      title: "Orders View",
      packageType: "Package Type",
      noOfReservation: "Number of people in reservation",
      orders: "Orders",
      sendSms: {
        title: "Send sms Notification",
        sentMessage: "Sms sent.",
        failedMessage: "Sms send failed.",
        numberValidatorMessage: "Must select order to send sms notification.",
        smsPlaceHolder: "Message:"
      },
      sendEmail: {
        title: "Send Email Notification",
        sentEmail: "Email sent.",
        failedEmail: "Email send failed.",
        numberValidatorEmail: "Must select order to send email notification.",
        emailPlaceHolder: "Subject:",
      },
      sendPushNotification: {
        title: "Send Push Notification",
        sentNotification: "Push notification sent.",
        failedNotification: "Push notification failed",
        notificationPlaceHolderTitle: "Title:",
        notificationPlaceHolderBody: "Body:"
      }
    },
    filters: {
      dateFilter: {
        label: "Date",
        none: "None",
        today: "Today",
        yesterday: "Yesterday",
        last7Days: "Last 7 days",
        last30Days: "Last 30 days",
        thisMonth: "This month",
        lastMonth: "Last month",
        custom: "Custom",
        startDate: "Start date",
        endDate: "End date"
      },
      statusFilter: {
        label: "Status",
        none: "None",
        pending: "Pending",
        ready: "Ready",
        inProgress: "In progress",
        completed: "Completed",
        cancelled: "Cancelled"
      },
      priceFilter: {
        label: "Price",
        none: "None",
        originPrice: "Origion price",
        ourPrice: "Our price",
        profit: "Profit"
      },
      chartOptions: {
        label: "Chart Type",
        barChart: "Bar Chart",
        linechart: "Line chart",
        cakeChart: "Cake chart"
      }
    },
    flightsPage: {
      title: "Flights detail",
      incomeFromFlights: "Income from flights",
      airlines: "Airlines",
      profitOfFlights: "Profit of flights",
      outcomeOfFlights: "Outcome of flights"
    },
    hotelsPage: {
      title: "Hotels detail",
      incomeFromHotels: "Income from Hotels",
      hotels: "Hotels",
      profitOfHotels: "Profit of Hotels",
      outcomeOfHotels: "Outcome of Hotels"
    },
    eventsPage: {
      title: "Events detail",
      incomeFromEvents: "Income from Events",
      events: "Events",
      profitOfEvents: "Profit of Events",
      outcomeOfEvents: "Outcome of Events"
    },
    reservationPage: {
      title: "Reservation detail",
      incomeFromReservations: "Outcome of Reservation",
      outcomeOfReservations: "Income from Reservation"
    },
    couponsPage: {
      title: "Coupons",
      couponCard: {
        active: "Active",
        inActive: "InActive",
        code: "Code:"
      },
      couponForm: {
        addTitle: "Add Coupon Details",
        editTitle: "Edit Coupon Details",
        couponCode: {
          name: "Coupon Code",
          errorMessage: "",
          requiredMessage: "You must enter your coupon code"
        },
        couponName: {
          name: "Coupon Name",
          errorMessage: "",
          requiredMessage: "You must enter your coupon name"
        },
        couponValue: {
          name: "Coupon Value",
          errorMessage: "",
          requiredMessage: "You must enter your coupon code"
        },
        discountType: {
          name: "Discount Type",
          errorMessage: "",
          requiredMessage: "You must enter your coupon name"
        },
        fixed: "Fixed",
        percentage: "Percentage",
        selectPlaceholderDiscount: "Select discount type",
        usage: {
          name: "Usage",
          errorMessage: "Usage must be valid number",
          requiredMessage: "You must enter usage"
        },
        startDateControl: {
          name: "Date",
          errorMessage: "",
          requiredMessage: "You must select start date"
        },
        endDateControl: {
          name: "Date",
          errorMessage: "",
          requiredMessage: "You must select end date"
        },
        statusType: {
          name: "Status Type",
          errorMessage: "",
          requiredMessage: "You must enter your coupon name"
        },
        currency: {
          name: "Currency",
          errorMessage: "",
          requiredMessage: "You must select your currency"
        },
        notSelected: "Not selected",
        selectPlaceholderStatus: "Select coupon status",
        startDate: "Start date",
        endDate: "End date",
        comment: {
          name: "Comment",
          errorMessage: "",
          requiredMessage: "Comment text is required"
        }
      }
    }    
  }
};

export default enI18n;
  