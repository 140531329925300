import { Component, ElementRef, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { TextDirectionController } from 'src/app/app.config';
import { RouterService } from 'src/app/libs/data-access/services/router/router.service';
import { PagesRoute } from 'src/app/pages/src/app/ui/routes/routes.enum';
import { ViewOrderComponent } from '../view-order/view-order.component';
import { Store } from '@ngrx/store';
import { ResetState } from 'src/app/actions/global.actions';

@Component({
  selector: 'tic-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {
  public directionController = new TextDirectionController();
  isOpen = false;
  openCurr = false;
  openLang = false;
  voPopup = false;
  @Input() packageTypeId!: number;
  @Input() active!: string;
  @Input() urlParams!:any;
  isHomePage = true;
  isTeamPage=false;
  data: any;
  isPageNotFound: boolean;

  constructor(
    private routerService: RouterService,
    private elementRef: ElementRef,
    private router: Router, 
    private globalStore: Store,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog) {
    this.setInitialsData();
    this.getCheckoutState();
  }

  navigateToHome():void {
    this.globalStore.dispatch(ResetState());
    this.router.navigate(['/home-page']);
  }

  private getCheckoutState(): void {
    this.routerService.isPaid$.subscribe((value) => {
      if (value) {
        this.active = "isDone";
      }
    });
  }

  private setInitialsData(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        const currentPath = this.router.url.split('?')[0].replace('/', '');
        if (currentPath === PagesRoute.PageNotFound || currentPath === PagesRoute.ErrorPage) {
          this.isPageNotFound = true;
        } else{
          this.isPageNotFound = false;
        }

        if (currentPath === 'home-page') {
          this.isHomePage = true;
        } else {
          this.isHomePage = false;
        }
        if (currentPath === PagesRoute.TeamPage) {
          this.isTeamPage = true;
        } else {
          this.isTeamPage = false;
        }
        this.data = this.activatedRoute.snapshot.queryParams;
      });
  }

  toggleClass() {
    this.isOpen = !this.isOpen;
    if (this.isOpen) {
      document.addEventListener('click', this.closeMenu);
    } else {
      document.removeEventListener('click', this.closeMenu);
    }
  }

  closeMenu = (event: MouseEvent) => {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isOpen = false;
    }
  }
  
  openCurrency() {
    this.openCurr = !this.openCurr;
    this.openLang = false;
  }

  openLanguage() {
    this.openLang = !this.openLang;
    this.openCurr = false;
  }

  openVoPopup() {
    const dialog = this.dialog.open(ViewOrderComponent)

    dialog.afterClosed().subscribe(res=>{
    })
  }

  closePopupVo(): void {
    this.voPopup = false;
  }

  ClickedOutVo(event: Event) {
    const evt = event.target as HTMLButtonElement;
    if(evt.classList.contains("view-order")) {
      this.voPopup = false;
    }
  }

  handleEventNavigation() {}

  handleFlightNavigation() {
    this.routerService.navigateAndAddParamsToUrl(
      {
        destinationLocationCode: this.urlParams.destinationLocationCode,
        inboundDate: this.urlParams.inboundDate,
        numberOfAdults: this.urlParams.numberOfAdults,
        numberOfChildren: this.urlParams.numberOfChildren,
        numberOfRooms: this.urlParams.numberOfRooms,
        originLocationCode: this.urlParams.originLocationCode,
        outboundDate: this.urlParams.outboundDate,
        packageType: this.urlParams.packageType,
        paxesChildrenAges: this.urlParams.paxesChildrenAges,
      },
      true,
      PagesRoute.FlightsPage
    );
  }

  handleHotelNavigation() {
    this.routerService.navigateAndAddParamsToUrl(
      {
        destinationLocationCode: this.urlParams.destinationLocationCode,
        inboundDate: this.urlParams.inboundDate,
        numberOfAdults: this.urlParams.numberOfAdults,
        numberOfChildren: this.urlParams.numberOfChildren,
        numberOfRooms: this.urlParams.numberOfRooms,
        originLocationCode: this.urlParams.originLocationCode,
        outboundDate: this.urlParams.outboundDate,
        packageType: this.urlParams.packageType,
        paxesChildrenAges: this.urlParams.paxesChildrenAges,
        flightOfferId:this.urlParams.flightOfferId
      },
      true,
      PagesRoute.HotelsPage
    );
  }
}
