import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Output,
} from "@angular/core";
import * as Highcharts from "highcharts";
import { DashboardFacade } from "../../../data-access/services/dashboard.facade";
import { Subject, takeUntil } from "rxjs";
import { ViewFilter } from "../../../data-access/model/filters";

@Component({
  selector: "tic-flight-detail",
  templateUrl: "./flight-detail.component.html",
  styleUrls: ["./flight-detail.component.scss"],
})
export class FlightDetailComponent {
  requestQueryAirline: any;
  requestQueryFlightIncome: any;
  requestQueryFlightOutcome: any;
  requestQueryFlightProfit:any;
  airlineFilter: any;
  incomeFilter: any;
  outcomeFilter: any;
  profitFilter: any;
  airlineChart: string = 'column';
  incomeChart: string = 'column';
  outcomeChart: string = 'column';
  profitChart: string = 'column';
  statusTitle: string = 'Status';
  status = [
    { value: 'none', viewValue: 'None' },
    { value: 'PENDING', viewValue: 'PENDING' },
    { value: 'READY', viewValue: 'READY' },
    { value: 'IN_PROGRESS', viewValue: 'IN PROGRESS' },
    { value: 'COMPLETED ', viewValue: 'COMPLETED ' },
    { value: 'CANCELLED', viewValue: 'CANCELLED' },
  ];
  priceTitle: string = 'Price';
  price = [
    { value: 'originPrice', viewValue: 'Origin Price' },
    { value: 'ourPrice', viewValue: 'Our Price' },
    { value: 'profit', viewValue: 'Profit' },
  ];

  colors = ["#4B49AC", "#98BDFF"];
  colors1 = ["#4B49AC", "#FFC100"];
  colors2 = ["#248AFD"];
  colors3 = ["#FF73A6"];

  Highcharts: typeof Highcharts = Highcharts;
  private destroy$ = new Subject();
  public airlineChartOptions: Highcharts.Options;
  public incomeChartOptions: Highcharts.Options;
  public outcomeChartOptions: Highcharts.Options;
  public profitChartOptions: Highcharts.Options;
  showSaveViewPopup: boolean=false;
  flightsView: any;
  Info: any;
  showValidation: boolean;

  constructor(
    private cdr: ChangeDetectorRef,
    public dashboardFacade: DashboardFacade
  ) { }

  ngOnInit() {
    this.setInitialState();
  }

  setInitialState() {
    this.dashboardFacade.loadPopularAirline({});
    this.dashboardFacade.loadFlightsIncome({});
    this.dashboardFacade.loadFlightsOutcome({});
    this.dashboardFacade.loadFlightsProfit({});
    this.toggleChart(this.airlineChart, "airlines");
    this.toggleChart(this.incomeChart, "income");
    this.toggleChart(this.outcomeChart, "outcome");
    this.toggleChart(this.profitChart, "profit");

    let user = localStorage.getItem('userId');
    this.dashboardFacade.getFlightsView({username: user});
    this.dashboardFacade.FlightsView$.pipe(takeUntil(this.destroy$)).subscribe((view) => {
      this.flightsView = view;
    });    
  }

  filterByRange(event: any,type: string) {
    if( type === 'airlines') {
      if( event != 'none') {
        const obj = {
          fromDate: event.startDate,
          toDate: event.endDate 
        }
        this.requestQueryAirline = {
          ...this.requestQueryAirline,
          ...obj
        }
      } else {
        const { fromDate, toDate, ...rest } = this.requestQueryAirline;
        this.requestQueryAirline = rest;
      }
      this.dashboardFacade.loadPopularAirline(this.requestQueryAirline);
    } 
    else if( type === 'income' ) {
      if( event != 'none') {
        const obj = {
          fromDate: event.startDate,
          toDate: event.endDate 
        }
        this.requestQueryFlightIncome = {
          ...this.requestQueryFlightIncome,
          ...obj
        }
      } else {
        const { fromDate, toDate, ...rest } = this.requestQueryFlightIncome;
        this.requestQueryFlightIncome = rest;
      }
      this.dashboardFacade.loadFlightsIncome(this.requestQueryFlightIncome);
    } 
    else if( type === 'outcome' ) {
      if( event != 'none') {
        const obj = {
          fromDate: event.startDate,
          toDate: event.endDate 
        }
        this.requestQueryFlightOutcome = {
          ...this.requestQueryFlightOutcome,
          ...obj
        }
      } else {
        const { fromDate, toDate, ...rest } = this.requestQueryFlightOutcome;
        this.requestQueryFlightOutcome = rest;
      }
      this.dashboardFacade.loadFlightsOutcome(this.requestQueryFlightOutcome);
    }
    else if( type === 'profit' ) {
      if( event != 'none') {
        const obj = {
          fromDate: event.startDate,
          toDate: event.endDate 
        }
        this.requestQueryFlightProfit = {
          ...this.requestQueryFlightProfit,
          ...obj
        }
      } else {
        const { fromDate, toDate, ...rest } = this.requestQueryFlightProfit;
        this.requestQueryFlightProfit = rest;
      }
      this.dashboardFacade.loadFlightsProfit(this.requestQueryFlightProfit);
    }
  }

  dataFilter(event: any,type: string,dropdownType: string) {
    if( type === 'airlines') {
      if( dropdownType === 'status' ) {
        if( event != 'none' ) {
          this.airlineFilter = {
            ...this.airlineFilter,
            orderStatus: event
          }
        } else {
          const { status, ...rest } = this.airlineFilter;
          this.airlineFilter = rest;
        }
      } else if( dropdownType === 'price' ) {
        if( event.option != 'none' ) {
          if( event.option ) {
            this.airlineFilter = {
              ...this.airlineFilter,
              priceType: event.option,
              minPrice: event.minPrice,
              maxPrice: event.maxPrice
            }
          } else {
            this.airlineFilter = {
              ...this.airlineFilter,
              minPrice: event.minPrice,
              maxPrice: event.maxPrice
            }
          }
        } else {
          const { priceType, minPrice, maxPrice, ...rest } = this.airlineFilter;
          this.airlineFilter = rest;
        }
      }
      this.requestQueryAirline = {
        ...this.requestQueryAirline,
        filters: this.airlineFilter
      }
      this.dashboardFacade.loadPopularAirline(this.requestQueryAirline);
    } else if( type === 'income' ) {
        if( dropdownType === 'status' ) {
          if( event != 'none' ) {
            this.incomeFilter = {
              ...this.incomeFilter,
              orderStatus: event
            }
          } else {
            const { status, ...rest } = this.incomeFilter;
            this.incomeFilter = rest;
          }
        } else if( dropdownType === 'price' ) {
          if( event.option != 'none' ) {
            if( event.option ) {
              this.incomeFilter = {
                ...this.incomeFilter,
                priceType: event.option,
                minPrice: event.minPrice,
                maxPrice: event.maxPrice
              }
            } else {
              this.incomeFilter = {
                ...this.incomeFilter,
                minPrice: event.minPrice,
                maxPrice: event.maxPrice
              }
            }
          } else {
            const { priceType, minPrice, maxPrice, ...rest } = this.incomeFilter;
            this.incomeFilter = rest;
          }
        }
        this.requestQueryFlightIncome = {
          ...this.requestQueryFlightIncome,
          filters: this.incomeFilter
        }
        this.dashboardFacade.loadFlightsIncome(this.requestQueryFlightIncome);
    } else if( type === 'outcome' ) {
      if( dropdownType === 'status' ) {
        if( event != 'none' ) {
          this.outcomeFilter = {
            ...this.outcomeFilter,
            orderStatus: event
          }
        } else {
          const { status, ...rest } = this.outcomeFilter;
          this.outcomeFilter = rest;
        }
      } else if( dropdownType === 'price' ) {
        if( event.option != 'none' ) {
          if( event.option ) {
            this.outcomeFilter = {
              ...this.outcomeFilter,
              priceType: event.option,
              minPrice: event.minPrice,
              maxPrice: event.maxPrice
            }
          } else {
            this.outcomeFilter = {
              ...this.outcomeFilter,
              minPrice: event.minPrice,
              maxPrice: event.maxPrice
            }
          }
        } else {
          const { priceType, minPrice, maxPrice, ...rest } = this.outcomeFilter;
          this.outcomeFilter = rest;
        }
      }
      this.requestQueryFlightOutcome = {
        ...this.requestQueryFlightOutcome,
        filters: this.outcomeFilter
      }
      this.dashboardFacade.loadFlightsOutcome(this.requestQueryFlightOutcome);
    } else if( type === 'profit' ) {
      if( dropdownType === 'status' ) {
        if( event != 'none' ) {
          this.profitFilter = {
            ...this.profitFilter,
            orderStatus: event
          }
        } else {
          const { status, ...rest } = this.profitFilter;
          this.profitFilter = rest;
        }
      } else if( dropdownType === 'price' ) {
        if( event.option != 'none' ) {
          if( event.option ) {
            this.profitFilter = {
              ...this.profitFilter,
              priceType: event.option,
              minPrice: event.minPrice,
              maxPrice: event.maxPrice
            }
          } else {
            this.profitFilter = {
              ...this.profitFilter,
              minPrice: event.minPrice,
              maxPrice: event.maxPrice
            }
          }
        } else {
          const { priceType, minPrice, maxPrice, ...rest } = this.profitFilter;
          this.profitFilter = rest;
        }
      }
      this.requestQueryFlightProfit = {
        ...this.requestQueryFlightProfit,
        filters: this.profitFilter
      }
      this.dashboardFacade.loadFlightsProfit(this.requestQueryFlightProfit);
    } 
  } 

  toggleChart(event: any, type: string) {
    if (type === "airlines") {
      this.dashboardFacade.popularAirlinesInfo$
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          if (data) {
            this.airlineChart = event;
            const airlines = Object.keys(data);
            const seriesData = airlines.map((airline) => {
              const orderIds = data[airline].map((item) => item.orderId);
              return {
                name: airline || 'Unknown', // Use a meaningful name for each category
                y: orderIds.length,
                orderIds: orderIds, // Store all order IDs for tooltip
              };
            });

            this.airlineChartOptions = {
              series: [
                {
                  name: 'Total Count',
                  type: event, // Use 'column' type for a column chart
                  data: seriesData,
                  showInLegend: false,
                  colorByPoint: true,
                  colors: this.colors,
                  borderRadius: 6
                },
              ],
              colors: this.colors,
              title: null,
              xAxis: {
                visible: true,
                type: "category",
                categories: airlines.map((airline) => airline || 'Unknown'), // Display as 'Package 2', 'Package 3', etc.
                title: {
                  text: "Airlines",
                },
              },
              yAxis: {
                visible: true,
                title: {
                  text: "Orders Count",
                },
                allowDecimals: false,
              },
              legend: {
                layout: "vertical",
                align: "right",
                verticalAlign: "middle",
                borderWidth: 0,
              },
              credits: {
                enabled: false,
              },
            };
          }
        });
    } else if (type === "income") {
      this.dashboardFacade.flightsIncomeInfo$
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          if (data) {
            this.incomeChart = event;
            const categories = Object.keys(data);
            const seriesData = categories.map((category) => {
              const income = data[category].income;
              return {
                name: category || 'Unknown', // Use a meaningful name for each category
                y: income,
                orderIds: income, // Store all order IDs for tooltip
              };
            });

            this.incomeChartOptions = {
              series: [
                {
                  name: 'Income',
                  type: event, // Use 'column' type for a column chart
                  data: seriesData,
                  colorByPoint: true,
                  colors: this.colors1,
                  showInLegend: false,
                },
              ],
              colors: this.colors1,
              title: null,
              xAxis: {
                visible: true,
                type: "category",
                categories: categories.map((cat) => cat || 'Unknown'), // Display as 'Package 2', 'Package 3', etc.
                title: {
                  text: "Date",
                },
              },
              yAxis: {
                visible: true,
                title: {
                  text: "Income",
                },labels: {
                  formatter: function () {
                      return '$' + this.value; // Format yAxis labels with $ sign
                  }
              }
              },
              legend: {
                layout: "vertical",
                align: "right",
                verticalAlign: "middle",
                borderWidth: 0,
              },
              credits: {
                enabled: false,
              },
            };
          }
        });
    } else if (type === "outcome") {
      this.dashboardFacade.flightsOutcomeInfo$
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          if (data) {
            this.outcomeChart = event;
            const categories = Object.keys(data);
            const seriesData = categories.map((category) => {
              const outcome = data[category].outcome;
              return {
                name: category || 'Unknown', // Use a meaningful name for each category
                y: outcome,
                orderIds: outcome, // Store all order IDs for tooltip
              };
            });

            this.outcomeChartOptions = {
              series: [
                {
                  name: 'Outcome',
                  type: event, // Use 'column' type for a column chart
                  data: seriesData,
                  colors: this.colors2,
                  showInLegend: false,
                },
              ],
              colors: this.colors2,
              title: null,
              xAxis: {
                visible: true,
                type: "category",
                categories: categories.map((cat) => cat || 'Unknown'), // Display as 'Package 2', 'Package 3', etc.
                title: {
                  text: "Date",
                },
              },
              yAxis: {
                visible: true,
                title: {
                  text: "Outcome",
                },labels: {
                  formatter: function () {
                      return '$' + this.value; // Format yAxis labels with $ sign
                  }
              }
              },
              legend: {
                layout: "vertical",
                align: "right",
                verticalAlign: "middle",
                borderWidth: 0,
              },
              credits: {
                enabled: false,
              },
            };
          }
        });
    } else if (type === "profit") {
      this.dashboardFacade.flightsProfitInfo$
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          if (data) {
            this.profitChart = event;
            const categories = Object.keys(data);
            const seriesData = categories.map((category) => {
              const profit = data[category][0].profit;
              return {
                name: category || 'Unknown', // Use a meaningful name for each category
                y: profit,
                orderIds: profit, // Store all order IDs for tooltip
              };
            });

            this.profitChartOptions = {
              series: [
                {
                  name: 'Profit',
                  type: event, // Use 'column' type for a column chart
                  data: seriesData,
                  showInLegend: false,
                },
              ],
              colors: this.colors3,
              title: null,
              xAxis: {
                visible: true,
                type: "category",
                categories: categories.map((cat) => cat || 'Unknown'), // Display as 'Package 2', 'Package 3', etc.
                title: {
                  text: "Date",
                },
              },
              yAxis: {
                visible: true,
                title: {
                  text: "Profit",
                },labels: {
                  formatter: function () {
                      return '$' + this.value; // Format yAxis labels with $ sign
                  }
              }
              },
              legend: {
                layout: "vertical",
                align: "right",
                verticalAlign: "middle",
                borderWidth: 0,
              },
              credits: {
                enabled: false,
              },
            };
          }
        });
    }
  }

  saveView(event:any) {
    let name =event.viewName;   
    let username=localStorage.getItem('userId');
    const flightFilters = {
      requestQueryAirline: this.requestQueryAirline,
      airlineChart: this.airlineChart,
      requestQueryFlightIncome: this.requestQueryFlightIncome,
      incomeChart: this.incomeChart,
      requestQueryFlightOutcome: this.requestQueryFlightOutcome,
      outcomeChart: this.outcomeChart,
      requestQueryFlightProfit: this.requestQueryFlightProfit,
      profitChart: this.profitChart
    }
    let data:ViewFilter={
      username:username,
      view:'flight',
      singleView: {
        name: name,
        param: this.objectToBase64(flightFilters)
      }
    }
    this.dashboardFacade.saveView(data);
    this.dashboardFacade.SaveViewInfo$.pipe(takeUntil(this.destroy$)).subscribe((info) => {
      this.Info = info;
    });
    this.showValidation = true;
    setTimeout(() => {
      this.showValidation = false;
      this.dashboardFacade.getFlightsView({username: username});
    }, 3000);
    this.showSaveViewPopup = false; 
  }

  objectToBase64(obj: any): string {
    const jsonStr = JSON.stringify(obj);
    return btoa(jsonStr);
  }
  onSelectionChange(event: any) {
    var flightFilter;
    if( event.value ) {
      flightFilter = this.base64ToObject(event.value);
    } else {
      flightFilter = {};
    }
    if( flightFilter && flightFilter.requestQueryAirline ) {
      this.requestQueryAirline = flightFilter.requestQueryAirline;
      this.dashboardFacade.loadPopularAirline(this.requestQueryAirline);
      if( flightFilter.requestQueryAirline.filters ) {
        this.airlineFilter = flightFilter.requestQueryAirline.filters;
      }
    } else {
      this.dashboardFacade.loadPopularAirline({});
    }
    if( flightFilter && flightFilter.requestQueryFlightIncome ) {
      this.requestQueryFlightIncome = flightFilter.requestQueryFlightIncome;
      this.dashboardFacade.loadFlightsIncome(this.requestQueryFlightIncome);
      if( flightFilter.requestQueryFlightIncome.filters ) {
        this.incomeFilter = flightFilter.requestQueryFlightIncome.filters;
      }
    } else {
      this.dashboardFacade.loadFlightsIncome({});
    }
    if( flightFilter && flightFilter.requestQueryFlightOutcome ) {
      this.requestQueryFlightOutcome = flightFilter.requestQueryFlightOutcome;
      this.dashboardFacade.loadFlightsOutcome(this.requestQueryFlightOutcome);
      if( flightFilter.requestQueryFlightOutcome.filters ) {
        this.outcomeFilter = flightFilter.requestQueryFlightOutcome.filters;
      }
    } else {
      this.dashboardFacade.loadFlightsOutcome({});
    }
    if( flightFilter && flightFilter.requestQueryFlightProfit ) {
      this.requestQueryFlightProfit = flightFilter.requestQueryFlightProfit;
      this.dashboardFacade.loadFlightsProfit(this.requestQueryFlightProfit);
      if( flightFilter.requestQueryFlightProfit.filters ) {
        this.profitFilter = flightFilter.requestQueryFlightProfit.filters;
      }
    } else {
      this.dashboardFacade.loadFlightsProfit({});
    }
    if( flightFilter && flightFilter.airlineChart ) {
      this.airlineChart = flightFilter.airlineChart;
    } else {
      this.airlineChart = 'column';
    }
    if( flightFilter && flightFilter.incomeChart ) {
      this.incomeChart = flightFilter.incomeChart;
    } else {
      this.incomeChart = 'column';
    }
    if( flightFilter && flightFilter.outcomeChart ) {
      this.outcomeChart = flightFilter.outcomeChart;
    } else {
      this.outcomeChart = 'column';
    }
    if( flightFilter && flightFilter.profitChart ) {
      this.profitChart = flightFilter.profitChart;
    } else {
      this.profitChart = 'column';
    }
    this.toggleChart(this.airlineChart, "airlines");
    this.toggleChart(this.incomeChart, "income");
    this.toggleChart(this.outcomeChart, "outcome");
    this.toggleChart(this.profitChart, "profit");
    this.cdr.detectChanges();
  }
  
  base64ToObject(base64Str: string): any {
    const jsonStr = atob(base64Str);
    return JSON.parse(jsonStr);
  }

  isObjectNotEmpty(obj: any): boolean {
    return obj && Object.keys(obj).length > 0;
  }
  openSaveView(){
    this.showSaveViewPopup = true;
  }
  closeViewPopup() {
    this.showSaveViewPopup = false;
  }
  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
