import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import * as Highcharts from 'highcharts';
import { DashboardFacade } from '../../../data-access/services/dashboard.facade';
import { Subject, takeUntil } from 'rxjs';
import { ViewFilter } from '../../../data-access/model/filters';

@Component({
  selector: 'tic-order-view',
  templateUrl: './order-view.component.html',
  styleUrls: ['./order-view.component.scss']
})
export class OrderViewComponent implements OnDestroy {
  selctedStatusOption:string='COMPLETED '; 
  priceTitle: string = 'Price';
  selctedPriceOption:string='originPrice';
  price = [
    { value: 'originPrice', viewValue: 'dashboard.filters.priceFilter.originPrice' },
    { value: 'ourPrice', viewValue: 'dashboard.filters.priceFilter.ourPrice' },
    { value: 'profit', viewValue: 'dashboard.filters.priceFilter.profit' },
  ];
  colors = ['#FFC100', '#248AFD', '#4B49AC'];
  colors2 = ["#248AFD"];
  requestQueryPackage: any;
  requestQueryNoOfPeoples: any;
  requestQueryOrders: any;
  packageFilter: any;
  noOfPeoplesFilter: any;
  ordersFilter: any;
  packageChart: string = 'pie';
  noOfPeoplesChart: string = 'column';
  ordersChart: string = 'column';
  Highcharts: typeof Highcharts = Highcharts;
  initialQuery
  private destroy$ = new Subject();
  public chartOptions: Highcharts.Options;
  public chartOptions1: Highcharts.Options;
  chartOptions2: Highcharts.Options;
  showSaveViewPopup: boolean=false;
  OrdersView: any;
  Info: any;
  showValidation: boolean;

  constructor(private cdr: ChangeDetectorRef,public dashboardFacade: DashboardFacade) {
  }

  ngOnInit() {
    this.setInitialState();
  }

  setInitialState() {
    this.dashboardFacade.loadPackages({});
    this.dashboardFacade.loadPersons({});
    this.dashboardFacade.getNumberOfOrders({});
    this.toggleChart(this.packageChart,'package');
    this.toggleChart(this.noOfPeoplesChart,'noOfPeoples');
    this.toggleChart(this.ordersChart,'orders');

    let user = localStorage.getItem('userId');
    this.dashboardFacade.getOrdersView({username: user});
    this.dashboardFacade.OrdersView$.pipe(takeUntil(this.destroy$)).subscribe((view) => {
      this.OrdersView = view;
    });     
  }

  filterByRange(event: any,type: string) {
    if( type === 'package') {
      if( event != 'none') {
        const obj = {
          fromDate: event.startDate,
          toDate: event.endDate 
        }
        this.requestQueryPackage = {
          ...this.requestQueryPackage,
          ...obj
        }
      } else {
        const { fromDate, toDate, ...rest } = this.requestQueryPackage;
        this.requestQueryPackage = rest;
      }
      this.dashboardFacade.loadPackages(this.requestQueryPackage);
    } else if( type === 'noOfPeoples' ) {
      if( event != 'none') {
        const obj = {
          fromDate: event.startDate,
          toDate: event.endDate 
        }
        this.requestQueryNoOfPeoples = {
          ...this.requestQueryNoOfPeoples,
          ...obj
        }
      } else {
        const { fromDate, toDate, ...rest } = this.requestQueryNoOfPeoples;
        this.requestQueryNoOfPeoples = rest;
      }
      this.dashboardFacade.loadPersons(this.requestQueryNoOfPeoples);
    } else if( type === 'orders' ) {
      if( event != 'none') {
        const obj = {
          fromDate: event.startDate,
          toDate: event.endDate 
        }
        this.requestQueryOrders = {
          ...this.requestQueryOrders,
          ...obj
        }
      } else {
        const { fromDate, toDate, ...rest } = this.requestQueryOrders;
        this.requestQueryOrders = rest;
      }
      this.dashboardFacade.getNumberOfOrders(this.requestQueryOrders);
    }
  }

  dataFilter(event: any,type: string,dropdownType: string) {
    if( type === 'package') {
      if( dropdownType === 'status' ) { 
        if( event != 'none' ) {
          this.packageFilter = {
            ...this.packageFilter,
            orderStatus: event
          }
        } else {
          const { status, ...rest } = this.packageFilter;
          this.packageFilter = rest;
        }
        
      } else if( dropdownType === 'price' ) {
        if( event.option != 'none' ) {
          if( event.option ) {
            this.packageFilter = {
              ...this.packageFilter,
              priceType: event.option,
              minPrice: event.minPrice,
              maxPrice: event.maxPrice
            }
          } else {
            this.packageFilter = {
              ...this.packageFilter,
              minPrice: event.minPrice,
              maxPrice: event.maxPrice
            }
          }
        } else {
          const { priceType, minPrice, maxPrice, ...rest } = this.packageFilter;
          this.packageFilter = rest;
        }
      }
      this.requestQueryPackage = {
        ...this.requestQueryPackage,
        filters: this.packageFilter
      }
      this.dashboardFacade.loadPackages(this.requestQueryPackage);
    } else if( type === 'noOfPeoples' ) {
        if( dropdownType === 'status' ) {
          if( event != 'none' ) {
            this.noOfPeoplesFilter = {
              ...this.noOfPeoplesFilter,
              orderStatus: event
            }
          } else {
            const { status, ...rest } = this.noOfPeoplesFilter;
            this.noOfPeoplesFilter = rest;
          }
        } else if( dropdownType === 'price' ) {
          if( event.option != 'none' ) {
            if( event.option ) {
              this.noOfPeoplesFilter = {
                ...this.noOfPeoplesFilter,
                priceType: event.option,
                minPrice: event.minPrice,
                maxPrice: event.maxPrice
              }
            } else {
              this.noOfPeoplesFilter = {
                ...this.noOfPeoplesFilter,
                minPrice: event.minPrice,
                maxPrice: event.maxPrice
              }
            }
          } else {
            const { priceType, minPrice, maxPrice, ...rest } = this.noOfPeoplesFilter;
            this.noOfPeoplesFilter = rest;
          }
        }
        this.requestQueryNoOfPeoples = {
          ...this.requestQueryNoOfPeoples,
          filters: this.noOfPeoplesFilter
        }
        this.dashboardFacade.loadPersons(this.requestQueryNoOfPeoples);
    } else if( type === 'orders' ) {
      if( dropdownType === 'status' ) {
        if( event != 'none' ) {
          this.ordersFilter = {
            ...this.ordersFilter,
            orderStatus: event
          }
        } else {
          const { status, ...rest } = this.ordersFilter;
          this.ordersFilter = rest;
        }
      } else if( dropdownType === 'price' ) {
        if( event.option != 'none' ) {
          if( event.option ) {
            this.ordersFilter = {
              ...this.ordersFilter,
              priceType: event.option,
              minPrice: event.minPrice,
              maxPrice: event.maxPrice
            }
          } else {
            this.ordersFilter = {
              ...this.ordersFilter,
              minPrice: event.minPrice,
              maxPrice: event.maxPrice
            }
          }
        } else {
          const { priceType, minPrice, maxPrice, ...rest } = this.ordersFilter;
          this.ordersFilter = rest;
        }
      }
      this.requestQueryOrders = {
        ...this.requestQueryOrders,
        filters: this.ordersFilter
      }
      this.dashboardFacade.getNumberOfOrders(this.requestQueryOrders);
    } 
  } 

  toggleChart(event: any,type: string) {
    if( type === 'package') {
      this.dashboardFacade.packagesInfo$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
        if( this.isObjectNotEmpty(data) ) {
          this.packageChart = event;
          this.chartOptions = {
            chart: {
              styledMode: false
            },
            series: [{
              name: 'Total',
              data:data?.map(item => ({name: item.packageType, y: item.totalOrders})),
              type: event,
              allowPointSelect: false,
              showInLegend: true
            }],
            legend: {
              layout: 'vertical',
              align: 'right',
              verticalAlign: 'middle',
              borderWidth: 0
            },
            xAxis: {
              visible: event !== 'pie',
              type: 'category',
              title: {
                text: 'Package'
              }
            },
            yAxis: {
              visible: event !== 'pie',
              title: {
                text: 'Total Orders'
              }
            },
            colors: this.colors,
            title: null,
            credits: {
              enabled: false 
           },
           responsive: {
            rules: [{
              condition: {
                maxWidth: 500 // Adjust for screens smaller than 500px
              },
              chartOptions: {
                legend: {
                  enabled: false // Hide the legend on smaller screens
                },
              
              }
            }]
          }
          };
        }
      });
    } else if( type === 'noOfPeoples' ) {
      this.dashboardFacade.personsInfo$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
        if( data ) {
          this.noOfPeoplesChart = event;
          const categories = Object.keys(data);
          const seriesData = categories.map(category => {
              const orderIds = data[category].map(item => item.orderId);
              return {
                  name: `Package ${category}`, // Use a meaningful name for each category
                  y: orderIds.length,
                  orderIds: orderIds  // Store all order IDs for tooltip
              };
          });
          const barColors = seriesData.map((_, index) => (index % 2 === 0 ? '#4b49ac' : '#ffc100'));
          // Update your Highcharts options
          this.chartOptions1 = {
              series: [{
                  name: 'Total',
                  type: event, // Use 'column' type for a column chart
                  data: seriesData,
                  showInLegend: false,
                  colorByPoint: true, // Allows custom colors for each point
                  colors: barColors 
              }],
              colors: this.colors,
              title: null,
              xAxis: {
                  visible: true,
                  type: 'category',
                  categories: categories.map(category => `${category} Persons`), // Display as 'Package 2', 'Package 3', etc.
                  title: {
                      text: 'Person'
                  }
              },
              yAxis: {
                  visible: true,
                  title: {
                      text: 'Orders Count'
                  }
              },
              legend: {
                  layout: 'vertical',
                  align: 'right',
                  verticalAlign: 'middle',
                  borderWidth: 0
              },
              credits: {
                  enabled: false
              }
          };
          
        }
      });
    } else if( type === 'orders' ) {
     this.dashboardFacade.numberOfOrders$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
        if( data ) {
          this.ordersChart = event;
          const categories = Object.keys(data);
          const seriesData = categories.map((category) => {
            const outcome = data[category];
            return {
              name: category || 'Unknown', // Use a meaningful name for each category
              y: Object.keys(outcome).length,
              orderIds: Object.keys(outcome), // Store all order IDs for tooltip
            };
          });
          this.chartOptions2 = {
            series: [
              {
                name: 'Total',
                type: event, // Use 'column' type for a column chart
                data: seriesData,
                showInLegend: false,
                colors: this.colors2,
              },
            ],
            colors: this.colors2,
            title: null,
            xAxis: {
              visible: true,
              type: "category",
              categories: categories.map((cat) => cat || 'Unknown'), // Display as 'Package 2', 'Package 3', etc.
              title: {
                text: "Date",
              },
            },
            yAxis: {
              visible: true,
              title: {
                text: "No Of Orders",
              }
            },
            legend: {
              layout: "vertical",
              align: "right",
              verticalAlign: "middle",
              borderWidth: 0,
            },
            credits: {
              enabled: false,
            },
          };
        }
      });      
    }
  } 

  saveView(event:any) {
    let name = event.viewName;   
    let username = localStorage.getItem('userId');    
    const ordersFilter = {
      requestQueryPackage: this.requestQueryPackage,
      packageChart: this.packageChart,
      requestQueryNoOfPeoples: this.requestQueryNoOfPeoples,
      noOfPeoplesChart: this.noOfPeoplesChart,
      requestQueryOrders: this.requestQueryOrders,
      ordersChart: this.ordersChart
    }
    let data: ViewFilter={
      username: username,
      view:'order',
      singleView: {
        name: name,
        param: this.objectToBase64(ordersFilter)
       }
     }
     this.dashboardFacade.saveView(data);
     this.dashboardFacade.SaveViewInfo$.pipe(takeUntil(this.destroy$)).subscribe((info) => {
      this.Info = info;
    });
    this.showValidation = true;
    setTimeout(() => {
      this.showValidation = false;
      this.dashboardFacade.getOrdersView({username: username});
    }, 3000);
    this.showSaveViewPopup = false;     

  }
  
  objectToBase64(obj: any): string {
    const jsonStr = JSON.stringify(obj);
    return btoa(jsonStr);
  }

  base64ToObject(base64Str: string): any {
    const jsonStr = atob(base64Str);
    return JSON.parse(jsonStr);
  }
  
  onSelectionChange(event: any) {
    var ordersFilter;
    if( event.value ) {
      ordersFilter = this.base64ToObject(event.value);
    } else {
      ordersFilter = {};
    }
    if( ordersFilter && ordersFilter.requestQueryPackage ) {
      this.requestQueryPackage = ordersFilter.requestQueryPackage;
      this.dashboardFacade.loadPackages(this.requestQueryPackage);
      if( ordersFilter.requestQueryPackage.filters ) {
        this.packageFilter = ordersFilter.requestQueryPackage.filters;
      }
    } else {
      this.dashboardFacade.loadPackages({});
    }
    if( ordersFilter && ordersFilter.requestQueryNoOfPeoples ) {
      this.requestQueryNoOfPeoples = ordersFilter.requestQueryNoOfPeoples;
      this.dashboardFacade.loadPersons(this.requestQueryNoOfPeoples);
      this.noOfPeoplesChart = ordersFilter.noOfPeoplesChart;
      if( ordersFilter.requestQueryNoOfPeoples.filters ) {
        this.noOfPeoplesFilter = ordersFilter.requestQueryNoOfPeoples.filters;
      }
    } else {
      this.dashboardFacade.loadPersons({});
    }
    if( ordersFilter && ordersFilter.requestQueryOrders ) {
      this.requestQueryOrders = ordersFilter.requestQueryOrders;
      this.dashboardFacade.getNumberOfOrders(ordersFilter.requestQueryOrders);
      if( ordersFilter.requestQueryOrders.filters ) {
        this.ordersFilter = ordersFilter.requestQueryOrders.filters;
      }
    } else {
      this.dashboardFacade.getNumberOfOrders({});
    }
    if( ordersFilter && ordersFilter.packageChart ) {
      this.packageChart = ordersFilter.packageChart;
    } else {
        this.packageChart = 'pie';  
    }
    if( ordersFilter && ordersFilter.noOfPeoplesChart ) {
      this.noOfPeoplesChart = ordersFilter.noOfPeoplesChart;
    } else {
        this.noOfPeoplesChart = 'column';
    }
    if( ordersFilter && ordersFilter.ordersChart ) {
      this.ordersChart = ordersFilter.ordersChart;
    } else {
        this.ordersChart = 'column';
    }
    this.toggleChart(this.packageChart,'package');
    this.toggleChart(this.noOfPeoplesChart,'noOfPeoples');
    this.toggleChart(this.ordersChart,'orders');
    this.cdr.detectChanges();
  }

  getDayOfWeek(date: Date): string {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return days[date.getDay()];
  }

  isObjectNotEmpty(obj: any): boolean {
    return obj && Object.keys(obj).length > 0;
  }

  openSaveView(){
    this.showSaveViewPopup = true;
  }
  closeViewPopup() {
    this.showSaveViewPopup = false;
  }
  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
