import { Component, Inject } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Subject, takeUntil } from "rxjs";
import { CheckoutFacade } from "src/app/libs/data-access/services/checkout/services/checkout.facade";
import { RouterService } from "src/app/libs/data-access/services/router/router.service";
import { EventsFacade } from "src/app/pages/events/src/app/data-access/services/events.facade";
import { FlightsFacade } from "src/app/pages/flights-page/src/app/data-access/services/flights.facade";
import { PagesRoute } from "src/app/pages/src/app/ui/routes/routes.enum";
import { isEmailValidator, isOrderIdValidator } from "../../utils/input-validators.utils";

@Component({
  selector: "tic-view-order",
  templateUrl: "./view-order.component.html",
  styleUrls: ["./view-order.component.scss"],
})
export class ViewOrderComponent {
  orderSearchFormGroup = new FormGroup({
    orderIdControl: new FormControl("", [Validators.required,isOrderIdValidator()]),
    emailControl: new FormControl("", [Validators.required,isEmailValidator()]),
  });
  isLoading: boolean = false;
  orderDetail: any = {};
  loadedHotel: any = {};
  loadedFlight: any = {};
  eventTotal: number = 0;
  selectedEvents: any[] = [];
  private destroy$ = new Subject<void>();

  constructor(
    private dialogRef: MatDialogRef<ViewOrderComponent>,
    private facadeCheckout: CheckoutFacade,
    public routerService: RouterService,
    public flightFacade: FlightsFacade,
    public eventFacade: EventsFacade,
    @Inject(MAT_DIALOG_DATA) public data?: any
  ) {}

  closePopupVo() {
    this.dialogRef.close(false);
  }

  checkError(type: any, message: string) {
    return type.hasError("required") ? message : "";
  }

  checkLengthError(type: any) {
    if(type!==''){
      return type.hasError("invalidOrderId") ? 'viewOrder.validatorMessage.idValid' : 'viewOrder.validatorMessage.space';
    }
    return '';
  }

  isObjectNotEmpty(obj: any): boolean {
    return obj && Object.keys(obj).length > 0;
  }

  checkValidEmailError(type: any) {
    if(type.value!==''){
      let value=type.hasError("invalidEmail") ? 'viewOrder.validatorMessage.emailValid' : 'viewOrder.validatorMessage.space';
      return value;
    }
    return '';
  }

  onSubmitForm() {
    this.isLoading = true;
    if (this.orderSearchFormGroup.valid) {
      const orderId = this.orderSearchFormGroup.get("orderIdControl").value;
      const email = this.orderSearchFormGroup.get("emailControl").value;
  
      // Encode order details to Base64
      const orderDetail = btoa(JSON.stringify({ orderId, email }));
  
      this.closePopupVo();
      this.routerService.navigateAndAddParamsToUrl(
        { ...this.data, orderDetail, viewOrder: true },
        false,
        PagesRoute.TripSummary
      );
    } else {
      this.orderSearchFormGroup.markAllAsTouched();
      this.isLoading = false;
    }
  }
}
