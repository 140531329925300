import { Component, OnInit, ChangeDetectorRef, OnDestroy, TemplateRef, ViewChild  } from '@angular/core';
import { TextDirectionController } from './app.config';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { PagesRoute } from './pages/src/app/ui/routes/routes.enum';
import { HotelsPageRoutes } from './pages/hotels/ui/routes/routes.enum';
import { PushNotificationService } from './push-notification.service';
import { SharedService } from './libs/common/services/shared.service';
import { NgxLoadingComponent, ngxLoadingAnimationTypes } from 'ngx-loading';
import { ConnectionService } from 'ng-connection-service';
import { OfflineDialogComponent } from './libs/ui/components/offline-dialog/offline-dialog.component';
import { MatDialog } from '@angular/material/dialog';
const PrimaryWhite = '#ff2976';
const SecondaryGrey = '#fff';
const PrimaryRed = '#dd0031';
const SecondaryBlue = '#1976d2';
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit,OnDestroy  {
  public directionController = new TextDirectionController();
  data: any;
  currentPage: any;
  hideHeaderFooter: boolean = false;


  //----------------------------------------------Loader---------------------------------------------------

  @ViewChild('ngxLoading', { static: false })
  ngxLoadingComponent!: NgxLoadingComponent;
  @ViewChild('customLoadingTemplate', { static: false })
  customLoadingTemplate!: TemplateRef<any>;
  @ViewChild('emptyLoadingTemplate', { static: false })
  emptyLoadingTemplate!: TemplateRef<any>;
  showingTemplate = false;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes; 
  public primaryColour = PrimaryWhite;
  public secondaryColour = SecondaryGrey;
  public coloursEnabled = false;
  public loadingTemplate!: TemplateRef<any>;
  public config = {
    animationType: ngxLoadingAnimationTypes.none,
    primaryColour: this.primaryColour,
    secondaryColour: this.secondaryColour,
    tertiaryColour: this.primaryColour,
    backdropBorderRadius: '3px',
  };

  constructor(private dialog: MatDialog, private sharedService: PushNotificationService,private router: Router, private activatedRoute: ActivatedRoute, private cdr: ChangeDetectorRef,
    public _sharedService:SharedService,
    private connectionService: ConnectionService
  ) {
    this.setInitialsData();
  }

  ngOnInit(): void {
    this.sharedService.subscribeToNotifications();
    this.sharedService.subscribeMessage();
  }

  ngAfterViewChecked() {
    if (this._sharedService.loading) {
      this.cdr.detectChanges(); // Manually trigger change detection to avoid the error
    }
  }
  toggleTemplate(): void {
    if (this.showingTemplate) {
      this.loadingTemplate = this.emptyLoadingTemplate;
    } else {
      this.loadingTemplate = this.customLoadingTemplate;
    }

    this.showingTemplate = !this.showingTemplate;
  }

  private setInitialsData(): void {
    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe(() => {
        const currentPath = this.router.url.split("?")[0];
        const segments = currentPath.split("/");
        const firstSegment = segments.length > 1 ? segments[1] : "";
        this.data = this.activatedRoute.snapshot.queryParams;

        switch (firstSegment) {
          case PagesRoute.EventPage:
            this.currentPage = "event";
            break;
          case PagesRoute.FlightsPage:
            this.currentPage = "flight";
            break;
          case PagesRoute.HotelsPage:
          case HotelsPageRoutes.Hotel_Page:
          case HotelsPageRoutes.List:
            this.currentPage = "hotel";
            break;
          case PagesRoute.LoginPage:
              this.currentPage = "login";
              this.hideHeaderFooter = true;
              break;
          case PagesRoute.Signup:
                this.currentPage = "register";
                this.hideHeaderFooter = true;
                break;
          case PagesRoute.AdminDashboard:
              this.currentPage = "admin-dashboard";
              this.hideHeaderFooter = true;
              break;
          case PagesRoute.TripSummary:
          case PagesRoute.TripCheckout:
            this.currentPage = "checkout";
            break;
          case PagesRoute.HomePage:
            this.currentPage = "home-page"
            this.hideHeaderFooter = false;
            break;
        }
      });
    this.connectionService.monitor().subscribe(isConnected => {  
      if(!isConnected.hasNetworkConnection) {
        this.dialog.open(OfflineDialogComponent, {
          disableClose: true
        });
      } else {
        this.dialog.closeAll();
      }
    });
  }

  ngOnDestroy() {
    this.sharedService.unsubscribeFromPushNotifications();
  }
}
