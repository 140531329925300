<ngx-loading
      [show]="_sharedService.loading"
      [config]="{
        animationType: ngxLoadingAnimationTypes.circle,
        primaryColour: primaryColour,
        secondaryColour: secondaryColour,
        backdropBorderRadius: '3px'
      }"
      [template]="loadingTemplate"
    ></ngx-loading>
<div class="app-component">
  <tic-navbar *ngIf="!hideHeaderFooter" [packageTypeId]="data?.packageType" [active]="currentPage"></tic-navbar>
  <router-outlet></router-outlet>
  <tic-footer *ngIf="!hideHeaderFooter" [class.background]="this.currentPage == 'home-page'"></tic-footer>
</div>
